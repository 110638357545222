import React, {FunctionComponent, useEffect} from 'react';
import { useNavigate} from "react-router-dom";
import authService from "../../service/auth/auth.service";
import './Header.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from '../../buyersedgelogo.png'
import IUser from "../../types/user/user.type";

type HeaderProps = {
    isLoggedIn: boolean,
}

export const Header : React.FC<HeaderProps> = (props) => {


    const isLoggedIn = props.isLoggedIn;
    const navigate = useNavigate();
    const isAdmin: boolean =  authService.isAdmin();

    const handleMainPage = (event: React.MouseEvent<HTMLAnchorElement>) =>{
        navigate("/main");
        window.location.reload();
    }

    const handleUserSettings = (event: React.MouseEvent<HTMLAnchorElement>) =>{
        navigate("/passwordChange");
        window.location.reload();
    }

    const handlePayorList = (event:React.MouseEvent<HTMLAnchorElement>) =>{
        navigate("/payorList");
        window.location.reload();
    }

    const handlePayeeList = (event:React.MouseEvent<HTMLAnchorElement>) =>{
        navigate("/payeeList");
        window.location.reload();
    }

    const handleInvoicesList = (event:React.MouseEvent<HTMLAnchorElement>) =>{
        navigate("/invoicesList");
        window.location.reload();
    }
    const handlePendingAch = (event:React.MouseEvent<HTMLAnchorElement>) =>{
        navigate("/pendingAch");
        window.location.reload();
    }
    const handleAchFilesList = (event:React.MouseEvent<HTMLAnchorElement>) =>{
        navigate("/achFilesList");
        window.location.reload();
    }

    const handleAchControlsList = (event:React.MouseEvent<HTMLAnchorElement>) =>{
        navigate("/achControlsList");
        window.location.reload();
    }

    const handleLogOff = (event: React.MouseEvent<HTMLAnchorElement>) =>{
        authService.logout();
        navigate("/");
        window.location.reload();
    }

    const handleUserList = (event: React.MouseEvent<HTMLAnchorElement>) =>{
        navigate("/userList");
        window.location.reload();
    }

    let user: IUser = {
        email: '',
        password:''
    }
    if(isLoggedIn){
        user = authService.getCurrentUserName();
        authService.isUserLoggedIn();
    }
    return (
    <div>
        <div className="header">
            <div><img className="image" src={logo} alt="logo"/></div>
            <div className="span">
            <span>BuyersEdge Payment Platform</span>
            </div>
        </div>
        {isLoggedIn &&
        <div>
            <div className="navbar">
                <a onClick={handleMainPage}>Home</a>
                <div className="dropdown">
                    <button className="dropbtn">View
                        <i className="fa fa-caret-down"></i>
                    </button>
                    <div className="dropdown-content">
                        {isAdmin &&
                            <a onClick={handleUserList}>Users</a>
                        }
                        <a onClick={handlePayorList}>Payors</a>
                        <a onClick={handlePayeeList}>Payees</a>
                        <a onClick={handlePendingAch}>Pending/Scheduled ACH</a>
                        <a onClick={handleAchFilesList}>Ach files</a>
                    </div>
                </div>
                <div className="dropdown">
                    <button className="dropbtn">Reports
                        <i className="fa fa-caret-down"></i>
                    </button>
                    <div className="dropdown-content">
                        <a>YTD Payment Report</a>
                    </div>
                </div>
                {isAdmin &&
                <div className="dropdown">
                    <button className="dropbtn">Controls
                        <i className="fa fa-caret-down"></i>
                    </button>
                    <div className="dropdown-content">
                        <a onClick={handleAchControlsList}>Ach Controls</a>
                    </div>
                </div>
                }
                <div className="dropdown" style={{float: "right", paddingRight: "100px"}}>
                    <button className="dropbtn">
                        <i className="fa fa-caret-down"> <FontAwesomeIcon icon="user"/></i>
                    </button>
                    <div className="dropdown-content">
                        <a onClick={handleUserSettings}>Change Password</a>
                        <a onClick={handleLogOff}>Logout</a>
                    </div>
                </div>
            </div>
        </div>
        }
    </div>
    );
}
