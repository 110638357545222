import React, { FunctionComponent } from 'react';
import './SupplierOnboardingHeader.scss';
import logo from '../../buyersedgelogo.png'

type HeaderProps = {
    payee: string,
}

export const SupplierOnboardingHeader: React.FC<HeaderProps> = (props) => {
    return (
        <div>
            <div className="supplierheader">
                <span className="supplierspan">BuyersEdge Payment Platform Supplier Onboarding</span>
            </div>
            <img className="supplierimage" src={logo} alt="logo" />
            <br />
            <div className="supplierwelcome"><strong>Welcome</strong> {props.payee}</div>
        </div>
    );
}
