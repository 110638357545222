import React, {useEffect, useState} from "react";
import "./invoicesList.scss"
import {Header} from "../../components/header/Header";
import {PayorSearch} from "../../components/payor/search/payorSearch";
import {PayeeSearch} from "../../components/payee/search/payeeSearch"
import {DateRangeSelector} from "../../components/dateRange/dateRangeSelector";
import {Payor} from "../../domain/payor/Payor";
import {Payee} from "../../domain/payee/Payee";
export const InvoicesList = (): JSX.Element => {
    const [payor, setPayor] = useState<Payor>(new Payor());
    const [payorSearchShown, setPayorSearchShown] = useState(false);
    const [payee, setPayee] = useState<Payee>(new Payee());
    const [payeeSearchShown, setPayeeSearchShown] = useState(false);
    const getSelectedPayor = (selectedPayor:Payor):void => {
        setPayor(selectedPayor)
        setPayorSearchShown(false)
    }
    const getSelectedPayee = (selectedPayee:Payee):void => {
        setPayee(selectedPayee)
        setPayeeSearchShown(false)
    }
    const setInitialToDate = () =>{
        var date = new Date();
        date.setMonth(date.getMonth()+1);
        return date;
    }
    const setInitialFromDate = () =>{
        var date = new Date();
        date.setMonth(date.getMonth()-1);
        return date;
    }

    const  selectedfromDate = (date:Date) => {
        setFromDate(date);
    }
    const  selectedtoDate = (date:Date) => {
        setToDate(date);
    }

    const [fromDate, setFromDate] = useState<Date>(setInitialFromDate);
    const [toDate, setToDate] = useState<Date>(setInitialToDate);
    return (
        <div>
            <Header isLoggedIn={true}></Header>
            <div className="_invoices_list_header">
                <div>Payor:</div>
                <div onMouseEnter={() => setPayorSearchShown(true)}
                     onMouseLeave={() => setPayorSearchShown(false)}
                >
                    <input type="text"  name="name"  value={payor?.name}
                        style = {{width: `50ch`}} readOnly={true}

                    />
                    {payorSearchShown &&
                       <PayorSearch selectPayor={getSelectedPayor}></PayorSearch>
                    }
                </div>
                <div>Payee:</div>
                <div onMouseEnter={() => setPayeeSearchShown(true)}
                     onMouseLeave={() => setPayeeSearchShown(false)}
                >
                    <input type="text"  name="name"  value={payee?.name}
                           style = {{width: `50ch`}} readOnly={true}

                    />
                    {payeeSearchShown &&
                    <PayeeSearch selectPayee={getSelectedPayee}></PayeeSearch>
                    }
                </div>
                <DateRangeSelector startDate={setInitialFromDate()} stopDate={setInitialToDate()} startDateSelected={selectedfromDate} stopDateSelected={selectedtoDate}></DateRangeSelector>
            </div>

        </div>
    )
}
