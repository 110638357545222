import * as React from 'react'
import {useState, useEffect, ChangeEvent, FormEventHandler, MouseEventHandler} from 'react';
import { useParams ,useNavigate} from "react-router-dom";
import {SupplierOnboardingHeader} from '../../components/supplierOnboarding/SupplierOnboardingHeader';
import {submitForm,restGetData} from '../../service/supplierOnboarding/OnboardingService'
import {CustomSelect} from "../../components/CustomSelect";
import SlInput from '@shoelace-style/shoelace/dist/react/input';
import type SlInputElement from '@shoelace-style/shoelace/dist/components/input/input.component.js'
import './OnboardingStyle.scss';
import logo from '../../irs_form_ss4.jpg'


interface IBusinessProfilePage {
    name: string
    companyStructure: string;
    taxId: string;
    legalBusinessName: string;
    doingBusinessAs: string;
    businessWebSite: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;
    typeOfBusiness: string;
    businessProductDescription: string;
}



export const BusinessProfilePage = (): JSX.Element => {
    const [businessProfile, setBusinessProfile] = useState<IBusinessProfilePage>({
        name:'',
        companyStructure:'',
        taxId: '',
        legalBusinessName:'',
        doingBusinessAs:'',
        businessWebSite:'',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
        typeOfBusiness: '',
        businessProductDescription:'',
    });

    const businessTypeOptions = [
        {label: "-- select one --", value: ""},
        {label: "Administrative Services", value: "Administrative_Services"},
        {label: "Baked Goods", value: "Baked_Goods"},
        {label: "Beverage - Alcohol", value: "Beverage_-_Alcohol"},
        {label: "Beverage - Non Alcohol", value: "Beverage_-_Non_Alcohol"},
        {label: "Broadline", value: "Broadline"},
        {label: "Center of Plate", value: "Center_of_Plate"},
        {label: "Chemicals", value: "Chemicals"},
        {label: "Coffee - Beans & Ground", value: "Coffee_-_Beans_&_Ground"},
        {label: "Computer/IT/Security", value: "Computer/IT/Security"},
        {label: "Consultants", value: "Consultants"},
        {label: "Customized Supply Chain Solutions", value: "Customized_Supply_Chain_Solutions"},
        {label: "Dairy", value: "Dairy"},
        {label: "Disposables", value: "Disposables"},
        {label: "Energy", value: "Energy"},
        {label: "Entertainment", value: "Entertainment"},
        {label: "Facilities Maintenance Products", value: "Facilities_Maintenance_Products"},
        {label: "Finance", value: "Finance"},
        {label: "Health & Wellness", value: "Health_&_Wellness"},
        {label: "Insurance", value: "Insurance"},
        {label: "Largewares", value: "Largewares"},
        {label: "Manufacturer", value: "Manufacturer"},
        {label: "Marketing Programs", value: "Marketing_Programs"},
        {label: "Merchant Services", value: "Merchant_Services"},
        {label: "Mobile / Internet", value: "Mobile_/_Internet"},
        {label: "Office Supplies", value: "Office_Supplies"},
        {label: "Payroll", value: "Payroll"},
        {label: "Produce", value: "Produce"},
        {label: "Refuse Removal", value: "Refuse_Removal"},
        {label: "Restaurant Apparel", value: "Restaurant_Apparel"},
        {label: "Scheduling Assistance", value: "Scheduling_Assistance"},
        {label: "Seafood", value: "Seafood"},
        {label: "Smallwares", value: "Smallwares"},
        {label: "Soda", value: "Soda"},
        {label: "Specialty Foods", value: "Specialty_Foods"},
        {label: "Textiles", value: "Textiles"},
        {label: "Training and Education", value: "Training_and_Education"},
        {label: "Other", value: "Other"},
    ]
    const stateOptions = [
        {label: "-- select one --", value: ""},
        {label: "Alabama", value: "AL"},
        {label: "Alaska", value: "AK"},
        {label: "American Samoa", value: "AS"},
        {label: "Arizona", value: "AZ"},
        {label: "Arkansas", value: "AR"},
        {label: "California", value: "CA"},
        {label: "Colorado", value: "CO"},
        {label: "Connecticut", value: "CT"},
        {label: "Delaware", value: "DE"},
        {label: "District of Columbia", value: "DC"},
        {label: "Florida", value: "FL"},
        {label: "Georgia", value: "GA"},
        {label: "Guam", value: "GU"},
        {label: "Hawaii", value: "HI"},
        {label: "Idaho", value: "ID"},
        {label: "Illinois", value: "IL"},
        {label: "Indiana", value: "IN"},
        {label: "Iowa", value: "IA"},
        {label: "Kansas", value: "KS"},
        {label: "Kentucky", value: "KY"},
        {label: "Louisiana", value: "LA"},
        {label: "Ohio", value: "OH"},
        {label: "Maine", value: "ME"},
        {label: "Maryland", value: "MD"},
        {label: "Massachusetts", value: "MA"},
        {label: "Michigan", value: "MI"},
        {label: "Minnesota", value: "MN"},
        {label: "Mississippi", value: "MS"},
        {label: "Missouri", value: "MO"},
        {label: "Montana", value: "MT"},
        {label: "Nebraska", value: "NE"},
        {label: "Nevada", value: "NV"},
        {label: "New Hampshire", value: "NH"},
        {label: "New Jersey", value: "NJ"},
        {label: "New Mexico", value: "NM"},
        {label: "New York", value: "NY"},
        {label: "North Carolina", value: "NC"},
        {label: "North Dakota", value: "ND"},
        {label: "Northern Mariana Islands", value: "MP"},
        {label: "Oklahoma", value: "OK"},
        {label: "Oregon", value: "OR"},
        {label: "Pennsylvania", value: "PA"},
        {label: "Puerto Rico", value: "PR"},
        {label: "Rhode Island", value: "RI"},
        {label: "South Carolina", value: "SC"},
        {label: "South Dakota", value: "SD"},
        {label: "Tennessee", value: "TN"},
        {label: "Texas", value: "TX"},
        {label: "Trust Territories", value: "TT"},
        {label: "Utah", value: "UT"},
        {label: "Vermont", value: "VT"},
        {label: "Virginia", value: "VA"},
        {label: "Virgin Islands", value: "VI"},
        {label: "Washington", value: "WA"},
        {label: "West Virginia", value: "WV"},
        {label: "Wisconsin", value: "WI"},
        {label: "Wyoming", value: "WY"},
    ]

    const companyStructureOptions = [
        {label: "-- select one --", value: ""},
        {label: "Multi Member LLC", value: "MULTI_MEMBER_LLC"},
        {label: "Private Partnership", value: "PRIVATE_PARTNERSHIP"},
        {label: "Private Corporation", value: "PRIVATE_CORPORATION"},
        {label: "Public Corporation", value: "PUBLIC_CORPORATION"},
        ]

    const [companyStructureError,setCompanyStructureError] = useState("");
    const [einError,setEinError] = useState("");
    const [legalBusinessNameError,setLegalBusinessNameError] = useState("");
    const [address1Error,setAddress1Error] = useState("");
    const [cityError,setCityError] = useState("");
    const [stateError,setStateError] = useState("");
    const [postalCodeError,setpostalCodeError] = useState("");
    const [businessWebSiteError,setBusinessWebSiteError] = useState("");
    const [businessProductDescriptionError,setBusinessProductDescription] = useState("");
    const [typeOfBusinessError,setTypeOfBusinessError] = useState("");
    const [submitError,setSubmitError] = useState("");
    const { onbordingToken } = useParams() as any
    const navigate = useNavigate();
    const blockInvalidChar = (e: { key: string; preventDefault: () => any; }) => ['e', 'E', '+', '-','.'].includes(e.key) && e.preventDefault();

    useEffect(() => {
        getData();
    }, []);

    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        const elementName = event.target.name;
        setBusinessProfile({
            ...businessProfile,
            [elementName]: event.target.value,
        });
    };

    const handleSlInput = (event: React.FormEvent<SlInputElement>) => {
        const elementName = event.currentTarget.name;
        if(elementName ==='taxId' && event.currentTarget.value.length > 9 ){
            setEinError('EIN number cannot be greater that 9 long');
        } else{
            setEinError('');
        }
        setBusinessProfile({
            ...businessProfile,
            [elementName]: event.currentTarget.value,
        });
    };

    const handleSelectInput = (event: ChangeEvent<HTMLSelectElement>) => {
        const elementName = event.target.name;
        setBusinessProfile({
            ...businessProfile,
            [elementName]: event.target.value,
        });
    };

    const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if(validate()){
            return;
        }
        const url =  'payeeOnboarding/companyProfile/' + onbordingToken;
        submitForm('POST', url, businessProfile, (status, response) => {
            if (status === 200 || status === 201) {
                navigate('/onboarding/principleContact/' + onbordingToken);
            } else {
                console.log('ERROR?!...', response);
                setSubmitError(response.errorMessage);
            }
        }).catch(error => {
            if(error.errorCode ==='BEP0020'){
                setSubmitError(error.stripeMessage);
            } else{
                setSubmitError(error.errorMessage);
            }
            }
        );
    };
    const validate = () => {
        let error: boolean = false;
        setEinError('');
        setLegalBusinessNameError('');
        if (businessProfile.taxId.length === 0) {
            setEinError('Ein is required');
            error = true;
        }
        if(!isNumber(businessProfile.taxId)){
            setEinError('Ein not a number.  0-9 no - or + ');
            error = true;
        }
        if (businessProfile.taxId.length !== 9) {
            setEinError('Ein must be 9 digits leading zeros');
            error = true;
        }
        if (businessProfile.legalBusinessName.length === 0) {
            setLegalBusinessNameError('Legal Business Name is required');
            error = true;
        }
        if (businessProfile.address1.length === 0) {
            setAddress1Error('Address is required');
            error = true;
        }
        if (businessProfile.city.length === 0) {
            setCityError('City is required');
            error = true;
        }
        if (businessProfile.state.length === 0) {
            setStateError('State is required');
            error = true;
        }
        if (businessProfile.postalCode.length === 0) {
            setpostalCodeError('Zip Code is required');
            error = true;
        }
        if (businessProfile.typeOfBusiness.length === 0) {
            setTypeOfBusinessError('Type of business is required');
            error = true;
        }
        return error;

    }
    const isUrl = (urlString:string) =>{
        var regexp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        return regexp.test(urlString);
    }
    const isNumber = (input:string) =>{
        return /^[0-9]*$/.test(input);
    }

    const getData = () =>{
        const url =  'payeeOnboarding/companyProfile/' + onbordingToken;
        restGetData('GET', url, (status, response) => {
            if (status === 200 || status === 201) {
                moveData(response);
            } else {
                console.log('ERROR?!...', response);
                setSubmitError(response.errorMessage);
            }
        }).catch(error => {
            setSubmitError(error);
            }
        );
    }
    const moveData = (response:IBusinessProfilePage ) => {
        setBusinessProfile(businessProfile => ({...businessProfile, ...response}));
    }



    return (<div>
        <SupplierOnboardingHeader payee={businessProfile.name}></SupplierOnboardingHeader>
        <div className="container">
            <h1>Company Information</h1>
            <div className="inputFieldElement">
                <label>Employer Identification Number (EIN)</label>
                <div className="paddedInput">
                    <SlInput style={{width: "150px"}}
                     type={"number"}
                     noSpinButtons={true}
                     name={"taxId"}
                             maxlength={9}
                     onInput={handleSlInput}
                    helpText={einError}
                             className={einError.length >0 ?'warn' : ''}
                    value={businessProfile.taxId}
                             onKeyDown={blockInvalidChar}
                    />
                </div>
            </div>
            <div className="inputFieldElement">
                <label>Legal Business Name </label>
                <div className="paddedInput">
                    <SlInput style={{width: "50ch"}}
                             name={"legalBusinessName"}
                             onInput={handleSlInput}
                             helpText={legalBusinessNameError}
                             className={legalBusinessNameError.length >0 ?'warn' : ''}
                             value={businessProfile.legalBusinessName}
                    />
                </div>
            </div>

            <div className="inputFieldElement">
                <label>Address1</label>
                <div className="paddedInput">
                    <SlInput style={{width: "50ch"}}
                             name={"address1"}
                             onInput={handleSlInput}
                             helpText={address1Error}
                             className={address1Error.length >0 ?'warn' : ''}
                             value={businessProfile.address1}
                    />
                </div>
            </div>
            <div className="inputFieldElement">
                <label>Address2</label>
                <div className="paddedInput">
                    <SlInput style={{width: "50ch"}}
                             name={"address2"}
                             onInput={handleSlInput}
                             value={businessProfile.address2}
                    />
                </div>
            </div>
            <div className="inputFieldElement">
                <label>City</label>
                <div className="paddedInput">
                    <SlInput style={{width: "50ch"}}
                             name={"city"}
                             onInput={handleSlInput}
                             helpText={cityError}
                             className={cityError.length >0 ?'warn' : ''}
                             value={businessProfile.city}
                    />
                </div>
            </div>
            <div className="inputFieldElement" style={{width: "50ch"}}>
                <CustomSelect options={stateOptions} header={"State"} value={businessProfile.state} name={"state"} handleSelectInput={handleSelectInput} />
                <span style={{color: "red"}}>{stateError}</span>
            </div>
            <div className="inputFieldElement">
                <label>Zip Code</label>
                <div className="paddedInput">
                    <SlInput style={{width: "20ch"}}
                             name={"postalCode"}
                             onInput={handleSlInput}
                             maxlength={10}
                             helpText={postalCodeError}
                             className={postalCodeError.length >0 ?'warn' : ''}
                             value={businessProfile.postalCode}
                    />
                </div>
            </div>
            <div className="inputFieldElement" style={{width: "50ch"}}>
                <CustomSelect options={businessTypeOptions} header={"Business Type"} value={businessProfile.typeOfBusiness} name={"typeOfBusiness"} handleSelectInput={handleSelectInput}></CustomSelect>
                <span style={{color: "red"}}>{typeOfBusinessError}</span>
            </div>
            <div className="inputFieldElement">
                <button className="button" onClick={buttonHandler}>Next</button>
            </div>
            <div>
                <span style={{color: "red"}}>{submitError}</span>
            </div>
        </div>
    </div>)
}
