import {PaymentSummary} from "./PaymentSummary";

export class SummaryPaymentsByType {
    constructor() {
        this.startDate = "";
        this.endDate = "";
        this.paymentType = "payor";
        this.payments = new Array();
    }
    startDate:string;
    endDate:string;
    paymentType:string;
    payments:PaymentSummary[];
}