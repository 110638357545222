import {BaseService} from "../BaseService";
import axios from "axios";
import authHeader from "../auth/auth-header";
import {Payee} from "../../domain/payee/Payee";
import authService from "../auth/auth.service";
import IUser from "../../types/user/user.type";
import {PayeeBankInfo} from "../../domain/payee/PayeeBankInfo";
const payeeUrl = BaseService.BACKEND_URL +'/payee';
class PayeeService extends BaseService {

    paths = {
        getPayees: () => payeeUrl  + '/list',
        getPayeeById: (id:number) => payeeUrl  + '/byId/' + id,
        updatePayee: (payor:Payee) => payeeUrl + '/byId' ,
        searchPayees: (searchParm:string) => payeeUrl  + '/search' + '/' + searchParm,
        getBankInformation: (platformId:string) => payeeUrl + '/bankInformation/' +platformId
    }
    public async getPayees(): Promise<Payee[]>{
        const response = await axios.get(this.paths.getPayees() ,{ headers: authHeader() } );
        return response.data;
    }
    public async getPayeeById(id:number): Promise<Payee>{
        const response = await axios.get(this.paths.getPayeeById(id) ,{ headers: authHeader() } );
        return response.data;
    }
    public async updatePayee(payee:Payee): Promise<Payee>{
        payee.apiUser = authService.getCurrentUserName().email;
        const response =await axios.put(this.paths.updatePayee(payee), payee,{ headers: authHeader() } );
        return response.data;
    }
    public async payeeSearch(searchString:string): Promise<Payee[]>{
        const response = await axios.get(this.paths.searchPayees(searchString) ,{ headers: authHeader() } );
        return response.data;
    }
    public async payeebankInfo(platformId:string): Promise<PayeeBankInfo>{
        const response = await axios.get(this.paths.getBankInformation(platformId) ,{ headers: authHeader() } );
        return response.data;
    }
}
export default new PayeeService();
