import React, {useEffect, useState} from "react";
import {Header} from "../../../../components/header/Header";
import {AchFile} from "../../../../domain/ach/payments/AchFile";
import {AchControls} from "../../../../domain/ach/AchControls";
import AchService from "../../../../service/ach/AchService";
import "../list/achControlsList.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
export const AchControlsList = (): JSX.Element => {
    useEffect(() => {
        getAchControlsData();
    }, []);
    const navigate = useNavigate();
    const [achControls, setAchControls] = useState<AchControls[]>([]);
    const getAchControlsData = () =>{
        AchService.getAchControls().then((achData =>{
                setAchControls(achData)
            }
        ));
    }

    const handleEditControl = (id:number) =>  (event: React.MouseEvent<SVGSVGElement>) =>{
        const controlId:number = achControls[id].id;
        navigate("/achControl/" + controlId);
        window.location.reload();
    }

    return (
        <div>
            <Header isLoggedIn={true}></Header>
            <div className="container">
                <div className="_achControls_list_header _achControls_list_header_color">
                    <div></div>
                    <div>Tyoe</div>
                    <div>User name</div>
                    <div>PIN</div>
                    <div>Immediate Destination</div>
                    <div>Immediate Destination Name</div>
                    <div>Immediate Origin</div>
                    <div>Immediate Origin Name</div>
                </div>
                {achControls.map((achControl, index) => {
                    return (
                        <div>
                            <div key={index} className="_achControls_list_header">
                                <div><FontAwesomeIcon icon="pen" onClick={handleEditControl(index)}/></div>
                                <div>{achControl.type}</div>
                                <div>{achControl.userName}</div>
                                <div>{achControl.pin}</div>
                                <div>{achControl.immediateDestination}</div>
                                <div>{achControl.immediateDestinationName}</div>
                                <div>{achControl.immediateOrigin}</div>
                                <div>{achControl.immediateOriginName}</div>
                            </div>
                        </div>
                    );
                })}

            </div>
        </div>
    )
}