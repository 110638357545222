import {Header} from "../../../components/header/Header";
import React, {useEffect, useState} from "react";
import {AchFile} from "../../../domain/ach/payments/AchFile";
import {PendingAch} from "../../../domain/ach/PendingAch";
import AchService from "../../../service/ach/AchService"
import "./pendingAchPayments.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {format} from "date-fns";
import Currency from "../../../domain/core/Currency";
import {AchFilesPayments} from "../files/achFilesPayments";
export const PendingAchPayments = (): JSX.Element => {
    useEffect(() => {
        getPendingAchData();
    }, []);

    const [pendingAch, setPendingAch] = useState<PendingAch[]>([]);
    const [scheduledAch, setScheduledAch] = useState<PendingAch[]>([]);
    const datetimeformat = 'MM/dd/yyyy';

    const getPendingAchData = () =>{
        AchService.getPendingAchPayments('pending').then((achData =>{
                setPendingAch(achData)
            }
        ));
        AchService.getPendingAchPayments('scheduled').then((achData =>{
                setScheduledAch(achData)
            }
        ));
    }
    return (
        <div>
            <Header isLoggedIn={true}></Header>
            <div className="container">
                <h2>Pending Ach Payments</h2>
                <div className="_pendingAch_list_header _pendingAch_list_header_color">
                    <div>Payor</div>
                    <div>Payee</div>
                    <div>Payment Date</div>
                    <div style={{textAlign: "right"}}>Amount</div>
                    <div style={{textAlign: "right"}}>AR</div>
                </div>
                {pendingAch.map((achFile, index) => {
                    return (
                        <div>
                            <div key={index} className="_pendingAch_list_header">
                                <div>{achFile.payorName}</div>
                                <div>{achFile.payeeName}</div>
                                <div>{format(new Date(achFile.dateChargeCreated), datetimeformat)}</div>
                                <div style={{textAlign: "right"}}><Currency amount={achFile.chargeAmount}/></div>
                                <div style={{textAlign: "center"}}>{achFile.arOnly}</div>
                            </div>
                        </div>
                    );
                })}
                <h2>Scheduled Ach Payments</h2>
                <div className="_pendingAch_list_header _pendingAch_list_header_color">
                    <div>Payor</div>
                    <div>Payee</div>
                    <div>Payment Date</div>
                    <div style={{textAlign: "right"}}>Amount</div>
                    <div style={{textAlign: "center"}}>AR</div>
                    <div>Scheduled Date</div>
                    <div>Pulled Date</div>
                </div>
                {scheduledAch.map((achFile, index) => {
                    return (
                        <div>
                            <div key={index} className="_pendingAch_list_header">
                                <div>{achFile.payorName}</div>
                                <div>{achFile.payeeName}</div>
                                <div>{format(new Date(achFile.dateChargeCreated), datetimeformat)}</div>
                                <div style={{textAlign: "right"}}><Currency amount={achFile.chargeAmount}/></div>
                                <div style={{textAlign: "center"}}>{achFile.arOnly}</div>
                                <div>{achFile.scheduledDate}</div>
                                <div>{achFile.fundsPulledDate}</div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}