import React, {ChangeEvent, useState} from "react";
import {Payor} from "../../../domain/payor/Payor";
import PayorService from "../../../service/payor/payorService"
import "./payorSearch.scss"

type PayorSearchProps = {
    selectPayor: (arg: Payor) => void
}


export const PayorSearch : React.FC<PayorSearchProps> = (props) => {
    const [payorName, setPayorName] = useState<string>('');
    const [payors, setPayors] = useState<Payor[]>([]);
    const [displayDropdown,setDisplayDropdown] = useState<boolean>(false);
    const getPayorData = () =>{
        PayorService.payorSearch(payorName).then((payorData)=>{
                setPayors(payorData);
                if(payorData.length >0){
                    setDisplayDropdown(true);
                }
            }
        )
    }

    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        setPayorName(event.target.value,);
        if(payorName.length>=3) {
            getPayorData();
        }
    };

    const handleSelectPayor = (id:number) =>  (event: React.MouseEvent) =>{
        props.selectPayor(payors[id]);
    }

    return (
        <div>
            <div><input type="text"  name="name"  value={payorName} onChange={handleInput}
                        style = {{width: `50ch`}}/></div>
            {displayDropdown &&
            <div className="_payor_search">
                    {payors.map((payor,index) => (
                        <div className="_payor_search_select" style={{width: `50ch`}} key={payor.id} onClick={handleSelectPayor(index)}>{payor.name} </div>
                    ))}
            </div>
            }
        </div>
    )
}
