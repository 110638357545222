import {Header} from "../../../components/header/Header";
import '../payeeList/payeeList.scss'
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Payee} from "../../../domain/payee/Payee";
import PayeeService from "../../../service/payee/payeeService"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import type SlInputElement from "@shoelace-style/shoelace/dist/components/input/input.component";
import SlInput from "@shoelace-style/shoelace/dist/react/input";


export const PayeeList = (): JSX.Element => {
    useEffect(() => {
       // getPayeeData();
    }, []);
    const navigate = useNavigate();
    const [payees, setPayees] = useState<Payee[]>([]);
    const [payeeSearch, setPayeeSearch] = useState<string>("");
    const getPayeeData = (search:string) =>{
        PayeeService.payeeSearch(search).then((payeeData)=>{
                setPayees(payeeData);
            }
        )
    }

    const handleEditPayee = (id:number) =>  (event: React.MouseEvent<SVGSVGElement>) =>{
        const payeeId:number = payees[id].id;
        navigate("/payee/" + payeeId);
        window.location.reload();
    }

    const handleSlInput = (event: React.FormEvent<SlInputElement>) => {
        setPayeeSearch(event.currentTarget.value)
        if(event.currentTarget.value.length>=3){
            getPayeeData(event.currentTarget.value);
        }
        if(event.currentTarget.value.length==0){
            setPayees([])
        }
    };


    return (
        <div>
            <Header isLoggedIn={true}></Header>
            <div className="container">
                <h1>Payees</h1>
                <div className="_payee_list_search">
                    <div>
                        <label>Name Search:</label>
                    </div>
                    <div>
                        <SlInput style={{width: "40ch"}}
                                 name={"payeeSearch"}
                                 onInput={handleSlInput}
                                 value={payeeSearch}
                        />
                    </div>
                </div>

                <div className="_payee-headers _payee_header_color">
                    <div></div>
                    <div>Name</div>
                    <div>Platform Id</div>
                    <div>email</div>
                    <div>Check Only</div>
                    <div>Status</div>
                </div>
                <div>
                    <hr></hr>
                </div>
                {payees.map((payee, index) => {
                    return (
                        <div key={index} className="_payee-headers">
                            <div><FontAwesomeIcon icon="pen" onClick={handleEditPayee(index)}/></div>
                            <div>{payee.name}</div>
                            <div>{payee.distributerPlatformId}</div>
                            <div>{payee.emailAddress}</div>
                            <div>{payee.nonElectronicPayee &&
                                <div>True</div>
                            }
                                {!payee.nonElectronicPayee &&
                                    <div>False</div>
                                }
                            </div>
                            <div>{payee.status}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}
