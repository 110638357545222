import {BaseService} from "../BaseService";
import axios from "axios";
import authHeader from "../auth/auth-header";
import {AchFilesRequest} from "../../domain/ach/payments/AchFilesRequest";
import {AchFile} from "../../domain/ach/payments/AchFile";
import {AchControls} from "../../domain/ach/AchControls";
import {PendingAch} from "../../domain/ach/PendingAch";
const achUrl = BaseService.BACKEND_URL +'/kotapay';
export class AchService extends BaseService {
    paths = {
        getAchFiles: (achFilesRequest:AchFilesRequest) => achUrl  + '/filesSent',
        downLoadAchFile: (fileId:number) => achUrl  + '/fileDownload/'+ fileId,
        getControls: () => achUrl  + '/controls/list',
        getControlsById: (id:number) => achUrl  + '/controls/' + id,
        addUpdateControls: () => achUrl  + '/controls',
        getPendingAch: (status:string) => achUrl  + '/pendingAch/' + status
    }
    public async getAchFiles(request:AchFilesRequest): Promise<AchFile[]>{
        const response = await axios.post(this.paths.getAchFiles(request) ,request,{ headers: authHeader() } );
        return response.data;
    }
    public async downLoadAchFile(fileId:number) {
        const response = await axios.get(this.paths.downLoadAchFile(fileId),{ headers: authHeader(),responseType:"blob"});
        return response.data;
    }

    public async getAchControls(): Promise<AchControls[]> {
        const response = await axios.get(this.paths.getControls(),{ headers: authHeader() });
        return response.data;
    }

    public async getAchControlsById(id:number): Promise<AchControls> {
        const response = await axios.get(this.paths.getControlsById(id),{ headers: authHeader() });
        return response.data;
    }
    public async updateAchControls(achControls:AchControls): Promise<AchControls> {
        const response = await axios.put(this.paths.addUpdateControls(),achControls,{ headers: authHeader() });
        return response.data;
    }
    public async getPendingAchPayments(status:string): Promise<PendingAch[]> {
        const response = await axios.get(this.paths.getPendingAch(status),{ headers: authHeader()});
        return response.data;
    }
}
export default new AchService();
