export class Address {
    constructor() {
        this.addressLine1='';
        this.addressLine2='';
        this.city='';
        this.state='';
        this.postalCode='';
        this.country=''
    }
    addressLine1:string;
    addressLine2:string;
    city:string;
    postalCode:string
    state:string
    country:string
}
