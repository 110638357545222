import * as React from 'react'
import {useState, useEffect, ChangeEvent}  from 'react';
import { useParams ,useNavigate} from "react-router-dom";
import {Header} from '../../components/header/Header';
import AuthService from "../../service/auth/auth.service";
import './login.scss';
export const Login = (): JSX.Element => {
    interface IUser {
        email: string
        password: string;
    }

    const [user, setUser] = useState<IUser>({
        email:'',
        password:''
    })
    const [loginError,setLoginError] = useState("");
    const navigate = useNavigate();
    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        const elementName = event.target.name;
        setUser({
            ...user,
            [elementName]: event.target.value,
        });
    };

    const loginButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setLoginError("");
        AuthService.login(user.email, user.password).then(
            () => {
                navigate("/main");
                window.location.reload();
            },
            error => {
                setLoginError("UserID/password incorrect");
            }
        );
    }
    return (<div>
        <Header isLoggedIn={false}></Header>
        <div className="form">
            <div className="container">
                <label htmlFor="email"><b>Email Address</b></label>
                <input className="loginInputWidths" type="text" name="email" placeholder="Enter Email" required value={user.email} onChange={handleInput}></input>

                    <label htmlFor="psw"><b>Password</b></label>
                    <input className="loginInputWidths" type="password" name="password" placeholder="Enter Password"  required value={user.password} onChange={handleInput}></input>
                        <button className="loginbutton" type="submit" onClick={loginButtonHandler}>Login</button>
                <span style={{color: "red"}}>{loginError}</span>
            </div>
            <div className="container" style={{backgroundColor:"#f1f1f1"}}>
                <span>Forgot <a href="#">password?</a></span>
            </div>
        </div>
    </div>);
}
