import React, {ChangeEvent, useEffect, useState} from "react";
import {Header} from "../../../components/header/Header";
import {Payor} from "../../../domain/payor/Payor";
import {useNavigate, useParams} from "react-router-dom";
import {Address} from "../../../domain/core/Address";
import PayorService from "../../../service/payor/payorService";
import PayorPaymentMethodService from "../../../service/payor/payorPaymentMethodService";
import '../payorForm/payorForm.scss'
import type SlInputElement from "@shoelace-style/shoelace/dist/components/input/input.component";
import SlInput from "@shoelace-style/shoelace/dist/react/input";
import {PayorBankInfo} from "../../../domain/payor/PayorBankInfo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import authService from "../../../service/auth/auth.service";

export const PayorForm = (): JSX.Element => {


    useEffect(() => {
        getData();
    }, []);
    const isAdmin: boolean =  authService.isAdmin();
    const navigate = useNavigate();
    const { id } = useParams() as any
    const [payor, setPayor] = useState<Payor>({
        id:0,
        name:'',
        email:'',
        phone:'',
        platformId:'',
        address: new Address(),
        customerToken: "",
        defaultSource: "",
        taxId:"",
        apiUser:""
    });

    const[address,setAddress] = useState<Address>({
        addressLine1:'',
        addressLine2:'',
        city:'',
        state:'',
        postalCode:'',
        country:''
    })

    const [bankInformation,setBankInformation] = useState<PayorBankInfo[]>([]);

    const[nameError,setNameError] = useState("");
    const[customerTokenError,setCustomerTokenError] = useState("");
    const[emailError,setEmailError] = useState("");
    const[platformIdError,setPlatformIdError] = useState("");
    const [einError,setEinError] = useState("");
    const blockInvalidChar = (e: { key: string; preventDefault: () => any; }) => ['e', 'E', '+', '-','.'].includes(e.key) && e.preventDefault();




    const getData = () => {
        PayorService.getPayorById(id).then((payorData)=>{
            moveData(payorData)
            getBankInfo(payorData.platformId)
            }
        )

    }

    const moveData = (response:Payor) =>{
        setPayor(payor => ({...payor, id: response.id}));
        setPayor(payor => ({...payor, name: response.name}));
        setPayor(payor => ({...payor, platformId: response.platformId}));
        setPayor(payor => ({...payor, email: response.email}));
        if(response.taxId !=null) {
            setPayor(payor => ({...payor, taxId: response.taxId}));
        }
        if(response.phone !=null) {
            setPayor(payor => ({...payor, phone: response.phone}));
        }
        if(response.customerToken !=null){
            setPayor(payor => ({...payor, customerToken: response.customerToken}));
        }
        if(response.address.addressLine1!=null){
            setAddress(address => ({...address,addressLine1:response.address.addressLine1}));
        }
        if(response.address.addressLine2!=null){
            setAddress(address => ({...address,addressLine2:response.address.addressLine2}));
        }
        if(response.address.city!=null){
            setAddress(address => ({...address,city:response.address.city}));
        }
        if(response.address.state!=null){
            setAddress(address => ({...address,state:response.address.state}));
        }
        if(response.address.postalCode!=null){
            setAddress(address => ({...address,postalCode:response.address.postalCode}));
        }

    }

    const getBankInfo = (platformId:string) =>{
        PayorPaymentMethodService.getPayorsBankInfo(platformId).then((payorBankData)=>{
            setBankInformation(payorBankData)
            }
        )
    }

    const handleSlInput = (event: React.FormEvent<SlInputElement>) => {
        const elementName = event.currentTarget.name;
        if(elementName ==='taxId' && event.currentTarget.value.length > 9 ){
            setEinError('EIN number cannot be greater that 9 long');
        } else{
            setEinError('');
        }
        setPayor({
            ...payor,
            [elementName]: event.currentTarget.value,
        });
    };
    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        const elementName = event.target.name;
        setPayor({
            ...payor,
            [elementName]: event.target.value,
        });
    };

    const handleAddressInput = (event: React.FormEvent<SlInputElement>) => {
        const elementName = event.currentTarget.name;
        setAddress({
            ...address,
            [elementName]: event.currentTarget.value,
        });
    };

    const validateForm = ()  => {
        let error = false;
        setNameError("");
        setEinError('');
        setCustomerTokenError("");
        setPlatformIdError("");
        setEmailError("");
        if(payor.name.length===0) {
            setNameError("Name cannot be blank")
            error=true;
        }
        if(payor.customerToken.length===0){
            setCustomerTokenError("Stripe token cannot be blank")
            error=true;
        }
        if(payor.platformId.length===0){
            setPlatformIdError("PlatformID cannot be blank")
            error=true;
        }
        if(payor.email.length===0){
            setEmailError("email cannot be blank")
            error=true;
        }
        if (payor.taxId.length === 0) {
            setEinError('Ein is required');
            error = true;
        }
        if(!isNumber(payor.taxId)){
            setEinError('Taxid not a number.  0-9 no - or + ');
            error = true;
        }
        if (payor.taxId.length !== 9) {
            setEinError('Ein must be 9 digits leading zeros');
            error = true;
        }
        return error;
    }

    const isNumber = (input:string) =>{
        return /^[0-9]*$/.test(input);
    }

    const saveButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if(!validateForm()) {
            let updatePayor: Payor = new Payor();
            updatePayor = payor;
            updatePayor.address = address;
            PayorService.updatePayor(updatePayor).then(() => {
                    navigate("/payorList");
                    window.location.reload();
                }
            )
        }
    }

    const cancelButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        navigate("/payorList");
        window.location.reload();
    }

    return (
        <div>
            <Header isLoggedIn={true}></Header>
            <div className="container">
                <div className="_payor-form">
                    <div>
                        <div className="inputFieldElement">
                            <label>Legal Business Name </label>
                            <div className="paddedInput">
                                <SlInput style={{width: "50ch"}}
                                         name={"name"}
                                         onInput={handleSlInput}
                                         helpText={nameError}
                                         className={nameError.length > 0 ? 'warn' : ''}
                                         value={payor.name}
                                />
                            </div>
                        </div>

                        <div className="inputFieldElement">
                            <label>Address </label>
                            <div className="paddedInput">
                                <SlInput style={{width: "50ch"}}
                                         name={"addressLine1"}
                                         onInput={handleAddressInput}
                                         value={address.addressLine1}
                                />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label> </label>
                            <div className="paddedInput">
                                <SlInput style={{width: "50ch"}}
                                         name={"addressLine2"}
                                         onInput={handleAddressInput}
                                         value={address.addressLine2}
                                />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label>City</label>
                            <div className="paddedInput">
                            <SlInput style={{width: "50ch"}}
                                     name={"city"}
                                     onInput={handleAddressInput}
                                     value={address.city}
                            />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label>State</label>
                            <SlInput style={{width: "50ch"}}
                                     name={"state"}
                                     onInput={handleAddressInput}
                                     value={address.state}
                            />
                        </div>
                        <div className="inputFieldElement">
                            <label>Zip Code</label>
                            <div className="paddedInput">
                            <SlInput style={{width: "20ch"}}
                                     name={"postalCode"}
                                     onInput={handleAddressInput}
                                     value={address.postalCode}
                            />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="inputFieldElement">
                            <label>Phone Number</label>
                            <div className="paddedInput">
                            <SlInput style={{width: "20ch"}}
                                     name={"phone"}
                                     onInput={handleSlInput}
                                     value={payor.phone}
                            />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label>Platform Id</label>
                            <div className="paddedInput">
                            <SlInput style={{width: "20ch"}}
                                     name={"platformId"}
                                     onInput={handleSlInput}
                                     helpText={platformIdError}
                                     className={platformIdError.length > 0 ? 'warn' : ''}
                                     value={payor.platformId}
                            />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label>Email</label>
                            <div className="paddedInput">
                            <SlInput style={{width: "50ch"}}
                                     name={"email"}
                                     onInput={handleSlInput}
                                     helpText={emailError}
                                     className={emailError.length > 0 ? 'warn' : ''}
                                     value={payor.email}
                            />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label>Employer Identification Number (EIN)</label>
                            <div className="paddedInput">
                                <SlInput style={{width: "150px"}}
                                         type={"number"}
                                         noSpinButtons={true}
                                         name={"taxId"}
                                         maxlength={9}
                                         onInput={handleSlInput}
                                         helpText={einError}
                                         className={einError.length > 0 ? 'warn' : ''}
                                         value={payor.taxId}
                                         onKeyDown={blockInvalidChar}
                                />
                            </div>
                        </div>
                        {isAdmin &&
                            <div>
                                    <div>Bank Information</div>
                                <div className="_payor_bank_info_config _payor_bank_info_header_color">
                                    <div>Name on Account</div>
                                    <div>Account Type</div>
                                    <div>Routing</div>
                                    <div>Account Number</div>
                                </div>
                                {bankInformation.map((bank, index) => {
                                        return (
                                            <div key={index} className="_payor_bank_info_config">
                                                <div>{bank.accountHolderName}</div>
                                                <div>{bank.accountHolderType}</div>
                                                <div>{bank.routingNumber}</div>
                                                <div>{bank.accountNumber}</div>
                                            </div>
                                        );
                                    })}
                            </div>
                    }
                    </div>
                    <div>

                    </div>
                </div>
                <div className="_buttons">
                    <div></div>
                    <button className="okButton" onClick={saveButtonHandler}>Save</button>
                    <div></div>
                    <button className="cancelButton" onClick={cancelButtonHandler}>Cancel</button>
                </div>
            </div>
        </div>
    )
}
