import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Header} from "../../../components/header/Header";
import {UserListRow} from "../userList/userListRow";
import UserService from "../../../service/user/userService";
import '../userList/userList.scss'
import {User} from "../../../domain/user/User";

export const UserList = (): JSX.Element => {
    useEffect(() => {
        getUserData();
    }, []);


    const navigate = useNavigate();
    const [users, setUsers] = useState<User[]>([]);

    const getUserData = () => {
        const userData = UserService.getUsers();
        userData.then((userData)=>{
            setUsers(userData);
        })
    }

    const AddNewHandler = (event: React.MouseEvent<HTMLButtonElement>) =>{
        event.preventDefault();
        navigate("/user");
        window.location.reload();
    }

    return (
        <div>
            <Header isLoggedIn={true}></Header>
            <div className="container">
                <div>
                <button style = {{float:"right"}} onClick={AddNewHandler}>Add User</button>
                </div>
                <div className="_users-row">
                    <div></div>
                    <div>Last Name</div>
                    <div></div>
                    <div>First Name</div>
                    <div></div>
                    <div>Email</div>
                    <div></div>
                    <div></div>
                    <div>Roles</div>
                </div>
                <div><hr></hr></div>
            {users.map((user, index) => {
                return (
                    <div key={index}>
                       <UserListRow user={user}></UserListRow>
                    </div>
                );
            })}
            </div>
        </div>
    )}
