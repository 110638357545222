import React, {useEffect, useState} from "react";
import {Header} from "../../../components/header/Header";
import AchService from "../../../service/ach/AchService"
import {useNavigate} from "react-router-dom";
import {AchFile} from "../../../domain/ach/payments/AchFile";
import {AchFilesRequest} from "../../../domain/ach/payments/AchFilesRequest";
import {DateRangeSelector} from "../../../components/dateRange/dateRangeSelector";
import "./achFilesList.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AchFilesPayments} from "./achFilesPayments";
import Currency from "../../../domain/core/Currency";
import { format } from 'date-fns';
import LoadingSpinner from "../../../components/LoadingSpinner";
export const AchFilesList = (): JSX.Element => {
    const setInitialToDate = () =>{
        var date = new Date();
        date.setMonth(date.getMonth()+1);
        return date;
    }
    const setInitialFromDate = () =>{
        var date = new Date();
        date.setMonth(date.getMonth()-1);
        return date;
    }
    const [fromDate, setFromDate] = useState<Date>(setInitialFromDate);
    const [toDate, setToDate] = useState<Date>(setInitialToDate);
    const datetimeformat = 'MM/dd/yyyy HH:mm:ss';


    useEffect(() => {
        getAchFilesData();
        const checked = new Array(achFiles.length).fill(false);
        setPaymentSelect(checked)
    }, [fromDate,toDate]);
    const navigate = useNavigate();
    const [achFiles, setAchFiles] = useState<AchFile[]>([]);

    const [paymentSelect, setPaymentSelect] = useState<Array<Boolean>>([]);
    const [isDownloading, setIsDownloading] = useState(false);


    const  selectedfromDate = (date:Date) => {
        setFromDate(date);
    }
    const  selectedtoDate = (date:Date) => {
        setToDate(date);
    }


    const getAchFilesData = () =>{
        var achRequest = new AchFilesRequest();
        achRequest.startDate=fromDate.toISOString().split('T')[0];
        achRequest.endDate=toDate.toISOString().split('T')[0];
        AchService.getAchFiles(achRequest).then((achData =>{
                setAchFiles(achData)
            }
        ));
    }
    const handleViewPayments = (index:number) =>  (event: React.MouseEvent<SVGSVGElement>) =>{
        const newState = [...paymentSelect];
        newState[index] = !newState[index];
        setPaymentSelect(newState);
    }

    const handleDownloadAchFile = (id:number,fileName:string) => () => {
        setIsDownloading(true);
        AchService.downLoadAchFile(id).then((fileData =>{
            const blob = new Blob([fileData], { type: "text/plain" });
                const url =URL.createObjectURL(blob)
                const link = document.createElement("a");
                link.download = fileName;
                link.href = url;
                link.click();
        }
        ));
        setIsDownloading(false);
    }
    return (
        <div>
            <Header isLoggedIn={true}></Header>
            <div className="container">
                <h1>Ach Files:</h1>
                <div style={{paddingBottom:"10px"}}>
                    <DateRangeSelector startDate={setInitialFromDate()} stopDate={setInitialToDate()}
                                       startDateSelected={selectedfromDate}
                                       stopDateSelected={selectedtoDate}></DateRangeSelector>
                </div>

                <div className="_achFiles_list_header _achFilesPayments_list_header_color">
                    <div></div>
                    <div>File Name</div>
                    <div></div>
                    <div>Date/Time Sent</div>
                    <div>Reference Name</div>
                    <div style={{textAlign: "right"}}>Number of Payments</div>
                    <div style={{textAlign: "right"}}>Amount of Payments</div>
                </div>
                {isDownloading && <LoadingSpinner/>}
                {achFiles.map((achFile, index) => {
                    return (
                        <div>
                            <div key={index} className="_achFiles_list_header">
                                {!paymentSelect[index] &&
                                    <div><FontAwesomeIcon icon="chevron-right" onClick={handleViewPayments(index)}/>
                                    </div>
                                }
                                {paymentSelect[index] &&
                                    <div><FontAwesomeIcon icon="chevron-down" onClick={handleViewPayments(index)}/>
                                    </div>
                                }
                                <div>{achFile.achFileName}</div>
                                <div><FontAwesomeIcon icon="download"
                                                      onClick={handleDownloadAchFile(achFile.id, achFile.achFileName)}/>
                                </div>
                                <div>{format(new Date(achFile.dateFileSent), datetimeformat)}</div>
                                <div>{achFile.referenceName}</div>
                                <div style={{textAlign: "right"}}>{achFile.totalPaymentCount}</div>
                                <div style={{textAlign: "right"}}><Currency amount={achFile.totalPaymentAmount}/></div>
                            </div>
                            {paymentSelect[index] &&
                                <AchFilesPayments achPayments={achFile.achPayments}></AchFilesPayments>
                            }
                        </div>
                    );
                })}
            </div>
        </div>
    )
}
