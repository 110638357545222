import React from 'react';
import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
    faUser,
    faPen,
    faFloppyDisk,
    faStopCircle,
    faEye,
    faDownload,
    faChevronRight, faChevronDown
} from "@fortawesome/free-solid-svg-icons";
import {Login} from './pages/login/login';
import {Main} from './pages/main/main';
import {PasswordChange} from './pages/user/passwordChange/passwordChange';
import {UserList} from './pages/user/userList/userList';
import {UserForm} from "./pages/user/userForm/userForm";
import {PayorList} from './pages/payor/payorList/payorList';
import {PayorForm} from "./pages/payor/payorForm/payorForm";
import {PayeeList} from "./pages/payee/payeeList/payeeList";
import {PayeeForm} from "./pages/payee/payeeForm/payeeForm"
import {BusinessProfilePage} from "./pages/supplierOnboarding/BusinessProfilePage";
import {AuthenticationPage} from "./pages/supplierOnboarding/AuthenticationPage";
import {PrincipleContactPage} from "./pages/supplierOnboarding/PrinicipleContactPage"
import {BankAccountInformationPage} from "./pages/supplierOnboarding/BankAccountInformationPage"
import authService from "./service/auth/auth.service";
import './App.css';
import ProtectedRoute, {ProtectedRouteProps} from "./components/router/ProtectedRoute";
import {InvoicesList} from "./pages/invoices/invoicesList";
import {AchFilesList} from "./pages/ach/files/achFilesList";
import '@shoelace-style/shoelace/dist/themes/light.css';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';
import {AchControlsList} from "./pages/ach/achControls/list/achControlsList";
import {AchControlForm} from "./pages/ach/achControls/achControlsForm/achControlForm";
import {PendingAchPayments} from "./pages/ach/pendingAch/pendingAchPayments";
function App() {
    library.add(fab, faUser,faPen,faFloppyDisk,faStopCircle,faEye,faDownload,faChevronRight,faChevronDown)
    setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.15.0/cdn/');
    const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
        isAuthenticated: !!authService.isUserLoggedIn(),
        authenticationPath: "/login",
    };
    return <BrowserRouter>
        <Routes>
            <Route path="/" element={<Login/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/onboarding/:onbordingToken" element={<AuthenticationPage/>}/>
            <Route path="/onboarding/businessProfile/:onbordingToken" element={<BusinessProfilePage/>}/>
            <Route path="/onboarding/principleContact/:onbordingToken" element={<PrincipleContactPage/>}/>
            <Route path="/onboarding/bankInformation/:onbordingToken" element={<BankAccountInformationPage/>}/>
            <Route
                path="/main"
                element={
                    <ProtectedRoute
                        {...defaultProtectedRouteProps}
                        outlet={<Main/>}
                    />
                }
            />
            <Route
                path="/passwordChange"
                element={
                    <ProtectedRoute
                        {...defaultProtectedRouteProps}
                        outlet={<PasswordChange/>}
                    />
                }
            />
            <Route
                path="/userList"
                element={
                    <ProtectedRoute
                        {...defaultProtectedRouteProps}
                        outlet={<UserList/>}
                    />
                }
            />
            <Route
                path="/user"
                element={
                    <ProtectedRoute
                        {...defaultProtectedRouteProps}
                        outlet={<UserForm/>}
                    />
                }
            />
            <Route
                path="/payorList"
                element={
                    <ProtectedRoute
                        {...defaultProtectedRouteProps}
                        outlet={<PayorList/>}
                    />
                }
            />
            <Route
                path="/payor/:id"
                element={
                    <ProtectedRoute
                        {...defaultProtectedRouteProps}
                        outlet={<PayorForm/>}
                    />
                }
            />
            <Route
                path="/payeeList"
                element={
                    <ProtectedRoute
                        {...defaultProtectedRouteProps}
                        outlet={<PayeeList/>}
                    />
                }
            />
            <Route
                path="/payee/:id"
                element={
                    <ProtectedRoute
                        {...defaultProtectedRouteProps}
                        outlet={<PayeeForm/>}
                    />
                }
            />
            <Route
                path="/invoicesList"
                element={
                    <ProtectedRoute
                        {...defaultProtectedRouteProps}
                        outlet={<InvoicesList/>}
                    />
                }
            />
            <Route
                path="/pendingAch"
                element={
                    <ProtectedRoute
                        {...defaultProtectedRouteProps}
                        outlet={<PendingAchPayments/>}
                    />
                }
            />
            <Route
                path="/achFilesList"
                element={
                    <ProtectedRoute
                        {...defaultProtectedRouteProps}
                        outlet={<AchFilesList/>}
                    />
                }
            />
            <Route
                path="/achControlsList"
                element={
                    <ProtectedRoute
                        {...defaultProtectedRouteProps}
                        outlet={<AchControlsList/>}
                    />
                }
            />
            <Route
                path="/achControl/:id"
                element={
                    <ProtectedRoute
                        {...defaultProtectedRouteProps}
                        outlet={<AchControlForm/>}
                    />
                }
            />
        </Routes>
    </BrowserRouter>;
}

export default App;

function DefaultPage() {
    return <div className="App">
        <header className="App-header">
            <img src={logo} className="App-logo" alt="logo"/>
            <p>
                Edit <code>src/App.tsx</code> and save to reload.
            </p>
            <a
                className="App-link"
                href="https://reactjs.org"
                target="_blank"
                rel="noopener noreferrer"
            >
                Learn React
            </a>
        </header>
    </div>
}
