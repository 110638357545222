import React, {ChangeEvent, useEffect, useState} from "react";
import IUser from "../../../types/user/user.type";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../userList/userList.scss'
import {User} from "../../../domain/user/User";
import UserService  from '../../../service/user/userService';

type UserProps = {
    user: User,
}



export const UserListRow : React.FC<UserProps> = (props) => {
    const [edit,setEdit] = useState(false);
    const [adminCheck,setAdminCheck] = useState(false)
    const [userCheck,setUserCheck] = useState(false)
    const [supplierCheck,setSupplierCheck] = useState(false)
    const [user, setUser] = useState<User>({
        roles: props.user.roles,
        id:props.user.id,
        lastName:props.user.lastName,
        firstName:props.user.firstName,
        email:props.user.email,
        password:''
    })

    useEffect(() => {
        setRoless();
    }, []);

    const setRoless = () =>{
        user.roles.forEach(function (value){
            if(value ==='ADMIN'){
                setAdminCheck(true);
            }
            if(value ==='USER'){
                setUserCheck(true);
            }
            if(value ==='SUPPLIER'){
                setSupplierCheck(true);
            }
        });
    }

    const handleEditUser = (event: React.MouseEvent<SVGSVGElement>) =>{
       setEdit(true)
    }

    const handleCancelEdit = ()=>{
        setEdit(false)
    }

    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        const elementName = event.target.name;
        setUser({
            ...user,
            [elementName]: event.target.value,
        });
    };

    const handlecheckBox = (event: ChangeEvent<HTMLInputElement>) => {
        const elementName = event.target.name;
        if(elementName==='adminCheckBox'){
            if(event.target.checked){
                setAdminCheck(true);
            } else{
                setAdminCheck(false);
            }
        }
        if(elementName==='userCheckBox'){
            if(event.target.checked){
                setUserCheck(true);
            } else{
                setUserCheck(false);
            }
        }
        if(elementName==='supplierCheckBox'){
            if(event.target.checked){
                setSupplierCheck(true);
            } else{
                setSupplierCheck(false);
            }
        }
    }

    const handleSave = () =>{
        props.user.lastName = user.lastName;
        props.user.firstName = user.firstName;
        props.user.email = user.email;
        setNewRoles();
        UserService.updateUser(user);
        setEdit(false)
    }

    const validateForm = ()  => {
        let error = false;

    }

    const setNewRoles = () =>{
        const newRoles:string[] = [];
        if(adminCheck){
            newRoles.push('ADMIN')
        }
        if(userCheck){
            newRoles.push('USER')
        }
        if(supplierCheck){
            newRoles.push('SUPPLIER')
        }
        user.roles = newRoles;
    }

    return (
        <div>
            {!edit &&
            <div className="_users-row">
                <div><FontAwesomeIcon icon="pen" onClick={handleEditUser}/></div>
                <div>
                    {user.lastName}
                </div>
                <div></div>
                <div>
                    {user.firstName}
                </div>
                <div></div>
                <div>
                    {user.email}
                </div>
                <div></div>
                <div><input type="checkbox"  disabled={true} checked={adminCheck}/></div>
                <div>Admin</div>
                <div><input type="checkbox"  disabled={true} checked={userCheck}/></div>
                <div>User</div>
                <div><input type="checkbox"  disabled={true} checked={supplierCheck}/></div>
                <div>Supplier</div>
            </div>
            }
            {edit &&
            <div className="_users-row">
                <div><FontAwesomeIcon icon="floppy-disk" onClick={handleSave} /><FontAwesomeIcon icon="stop-circle" onClick={handleCancelEdit}/></div>
                <div>
                    <input type="text"  name="lastName"  value={user.lastName} onChange={handleInput}/>
                </div>
                <div></div>
                <div>
                    <input type="text"  name="firstName"  value={user.firstName} onChange={handleInput}/>
                </div>
                <div></div>
                <div>
                    <input type="text"  name="email"  value={user.email} onChange={handleInput}/>
                </div>
                <div></div>
                <div><input type="checkbox"  name="adminCheckBox" checked={adminCheck} onChange={handlecheckBox}/></div>
                <div>Admin</div>
                <div><input type="checkbox"  name="userCheckBox" checked={userCheck} onChange={handlecheckBox}/></div>
                <div>User</div>
                <div><input type="checkbox"  name="supplierCheckBox" checked={supplierCheck} onChange={handlecheckBox}/></div>
                <div>Supplier</div>
            </div>
            }
        </div>
    );
}
