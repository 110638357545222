export class AchFilesRequest {
    constructor() {
        this.startDate = '';
        this.endDate = '';
        this.status = '';
    }
    startDate:string;
    endDate:string;
    status:string;
}
