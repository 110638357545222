import {BaseService} from "../BaseService";
import {Payee} from "../../domain/payee/Payee";
import {SummaryPaymentsByType} from "../../domain/payments/SummaryPaymentsByType";
import axios from "axios";
import authHeader from "../auth/auth-header";
const paymentUrl = BaseService.BACKEND_URL +'/payment';
class PaymentService extends BaseService {
    paths = {
        topPayments: (topPaymentsRequest:SummaryPaymentsByType) => paymentUrl  + '/topPayments',
    }
    public async topPayments(topPaymentsRequest:SummaryPaymentsByType): Promise<SummaryPaymentsByType>{
        const response =await axios.post(this.paths.topPayments(topPaymentsRequest), topPaymentsRequest,{ headers: authHeader() } );
        return response.data;
    }
}
export default new PaymentService();