import * as React from 'react'
import {useState, useEffect, ChangeEvent}  from 'react';
import { useParams,useNavigate } from "react-router-dom";
import {SupplierOnboardingHeader} from '../../components/supplierOnboarding/SupplierOnboardingHeader';
import {submitForm,restGetData} from '../../service/supplierOnboarding/OnboardingService'
import {getClientipAddress} from '../../service/supplierOnboarding/GetClientIpAddress';
import LoadingSpinner from "../../components/LoadingSpinner";
import { PatternFormat } from 'react-number-format';
import SlInput from '@shoelace-style/shoelace/dist/react/input';
import type SlInputElement from '@shoelace-style/shoelace/dist/components/input/input.component.js'
import './OnboardingStyle.scss';

interface IBankAccountInformation {
    name: string;
    bankName: string;
    accountHolderName: string;
    accountHolderType: string;
    routingNumber: string;
    accountNumber:string;
    confirmAccountNumber:string
    clientIpAddress:string
}


export const BankAccountInformationPage = (): JSX.Element => {
    const [bankInformation, setbankInformation] = useState<IBankAccountInformation>({
        name:'',
        bankName: '',
        accountHolderName: '',
        accountHolderType: '',
        routingNumber: '',
        accountNumber:'',
        confirmAccountNumber:'',
        clientIpAddress:''
    })
    const [bankNameError,setBankNameError] = useState("");
    const [accountHolderNameError,setAccountHolderNameError] = useState("");
    const [accountHolderTypeError,setAccountHolderTypeError] = useState("");
    const [routingNumberError,setRoutingNumberError] = useState("");
    const [accountNumberError,setAccountNumberError] = useState("");
    const [confirmAccountNumberError,setConfirmAccountNumberError] = useState("");
    const [retrievedIpAddress,setRetrievedIpAddress] = useState("");
    const [submitError,setSubmitError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const blockInvalidChar = (e: { key: string; preventDefault: () => any; }) => ['e', 'E', '+', '-','.'].includes(e.key) && e.preventDefault();
    const bankInfo:IBankAccountInformation = {
        name:'',
        bankName: '',
        accountHolderName: '',
        accountHolderType: '',
        routingNumber: '',
        accountNumber:'',
        confirmAccountNumber:'',
        clientIpAddress:''
    }
    useEffect(() => {
        getData();
        //getIpAddress();
    }, []);
    const getData = () =>{
        const url =  'payeeOnboarding/bankInfo/' + onbordingToken;
        restGetData('GET', url, (status, response) => {
            if (status === 200 || status === 201) {
                moveData(response);
            } else {
                console.log('ERROR?!...', response);

            }
        }).catch(error => {
                console.log(error);
            }
        );
    }
    const moveData = (response:IBankAccountInformation) => {
        setbankInformation(bankInformation => ({...bankInformation, name: response.name}));
        if (response.bankName != null) {
            setbankInformation(bankInformation => ({...bankInformation, bankName: response.bankName}));
        }
        if (response.accountHolderName != null) {
            setbankInformation(bankInformation => ({
                ...bankInformation,
                accountHolderName: response.accountHolderName
            }));
        }
        if (response.accountHolderType != null) {
            setbankInformation(bankInformation => ({
                ...bankInformation,
                accountHolderType: response.accountHolderType
            }));
        } else {
            setbankInformation(bankInformation => ({
                ...bankInformation,
                accountHolderType: "checking"
            }));
        }
        if (response.routingNumber != null) {
            setbankInformation(bankInformation => ({...bankInformation, routingNumber: response.routingNumber}));
        }
        if (response.accountNumber != null) {
            setbankInformation(bankInformation => ({...bankInformation, accountNumber: response.accountNumber}));
        }
        if (response.confirmAccountNumber != null) {

        setbankInformation(bankInformation => ({
            ...bankInformation,
            confirmAccountNumber: response.confirmAccountNumber
        }));
    }
    }
    const validate = () => {
        let error: boolean = false;
        setBankNameError('');
        setAccountHolderNameError('');
        setAccountHolderTypeError('');
        setRoutingNumberError('');
        setAccountNumberError('');
        setConfirmAccountNumberError('');
        if(bankInformation.bankName.length===0){
            setBankNameError('Bank name is required');
            error = true;
        }
        if(bankInformation.accountHolderName.length===0){
            setAccountHolderNameError('Account holder name is required');
            error = true;
        }
        if(bankInformation.accountHolderType.length===0){
            setAccountHolderTypeError('Account holder type is required');
            error = true;
        }
        if(bankInformation.routingNumber.length===0){
            setRoutingNumberError('Routing number is required');
            error = true;
        }
        if(!isNumber(bankInformation.routingNumber)){
            setRoutingNumberError('Routing number must be a number');
            error = true;
        }
        if(bankInformation.accountNumber.length===0){
            setAccountNumberError('Account number is required');
            error = true;
        }
        if(!isNumber(bankInformation.accountNumber)){
            setAccountNumberError('Account number must be a number');
            error = true;
        }
        if(bankInformation.confirmAccountNumber.length===0){
            setConfirmAccountNumberError('Confirm account number is required');
            error = true;
        } else {
            if (bankInformation.confirmAccountNumber !== bankInformation.accountNumber) {
                setConfirmAccountNumberError('Confirm account number does not match account number');
                error = true;
            }
        }
        return error;
    }

    const isNumber = (input:string) =>{
        return /^[0-9]*$/.test(input);
    }

    const getIpAddress = () =>{
        const url =  'http://api.ipify.org/?format=json' + onbordingToken;
        getClientipAddress( (status, response) => {
            if (status === 200 || status === 201) {
               setRetrievedIpAddress(response.ip)
            } else {
                console.log('ERROR?!...', response);

            }
        }).catch(error => {
                console.log(error);
            }
        );
    }
    const { onbordingToken } = useParams() as any
    const navigate = useNavigate();
    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        const elementName = event.target.name;
        setbankInformation({
            ...bankInformation,
            [elementName]: event.target.value,
        });
    };

    const handleSlInput = (event: React.FormEvent<SlInputElement>) => {
        const elementName = event.currentTarget.name;
        if(elementName ==='routingNumber' && event.currentTarget.value.length >9){
            setRoutingNumberError("Routing number cannot be greater than 9 positions");
        } else{
            setRoutingNumberError("");
        }
        setbankInformation({
            ...bankInformation,
            [elementName]: event.currentTarget.value,
        });
    };

    const backButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        navigate('/onboarding/principleContact/' + onbordingToken);
    }
    const submitButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if(validate()){
            return;
        }
        setIsLoading(true);
        bankInfo.bankName = bankInformation.bankName;
        bankInfo.accountHolderName = bankInformation.accountHolderName;
        bankInfo.accountHolderType = bankInformation.accountHolderType;
        bankInfo.routingNumber = bankInformation.routingNumber;
        bankInfo.accountNumber= bankInformation.accountNumber;
        bankInfo.confirmAccountNumber=bankInformation.confirmAccountNumber;
        bankInfo.clientIpAddress= retrievedIpAddress;

        const url =  'payeeOnboarding/bankInfo/' + onbordingToken;
        submitForm('POST', url, bankInfo, (status,response) => {
            if (status === 200 || status === 201) {
                setIsLoading(false);
                window.location.href ="https://buyersedgeplatform.com";
            } else {
                setIsLoading(false);
                if(response.errorCode ==='BEP0020'){
                    setSubmitError(response.stripeMessage)
                } else{
                    setSubmitError(response.errorMessage);
                }

            }
        }).catch(error => {
                console.log(error);
            setSubmitError(error)
            }
        );

    };
    return (<div>
        <SupplierOnboardingHeader payee={bankInformation.name}></SupplierOnboardingHeader>
        <div className="container">
            <h1>Bank Information</h1>

            <div className="inputFieldElement">
                <label>Bank Name</label>
                <div className="paddedInput">
                    <SlInput style={{width: "50ch"}}
                             name={"bankName"}
                             onInput={handleSlInput}
                             helpText={bankNameError}
                             className={bankNameError.length >0 ?'warn' : ''}
                             value={bankInformation.bankName}
                    />
                </div>
            </div>
            <div className="inputFieldElement">
                <label>Account Holder Name</label>
                <div className="paddedInput">
                    <SlInput style={{width: "50ch"}}
                             name={"accountHolderName"}
                             onInput={handleSlInput}
                             helpText={accountHolderNameError}
                             className={accountHolderNameError.length >0 ?'warn' : ''}
                             value={bankInformation.accountHolderName}
                    />
                </div>
            </div>
            <div className="inputFieldElement">
                <label>Bank Account Type: </label>
                <div className="form-field">
                    <input id="accountTypeBank" type="radio" value="checking" checked={bankInformation.accountHolderType === 'checking'} name="accountHolderType" onChange={handleInput}/>
                    <label htmlFor="accountTypeBank">Checking</label>
                </div>
                <div className="form-field">
                    <input id="accountTypeIndividual" type="radio" value="savings" checked={bankInformation.accountHolderType === 'savings'} name="accountHolderType" onChange={handleInput}/>
                    <label htmlFor="accountTypeIndividual">Savings</label>
                </div>
                <span style={{color: "red"}}>{accountHolderTypeError}</span>
            </div>
            <div className="inputFieldElement">
                <label>Routing Number</label>
                <div className="paddedInput">
                    <SlInput style={{width: "20ch"}}
                             name={"routingNumber"}
                             onInput={handleSlInput}
                             maxlength={9}
                             noSpinButtons={true}
                             type={"number"}
                             helpText={routingNumberError}
                             className={routingNumberError.length >0 ?'warn' : ''}
                             value={bankInformation.routingNumber}
                             onKeyDown={blockInvalidChar}
                    />
                </div>
            </div>
            <div className="inputFieldElement">
                <label>Account Number</label>
                <div className="paddedInput">
                    <SlInput style={{width: "20ch"}}
                             name={"accountNumber"}
                             onInput={handleSlInput}
                             noSpinButtons={true}
                             type={"number"}
                             helpText={accountNumberError}
                             className={accountNumberError.length >0 ?'warn' : ''}
                             value={bankInformation.accountNumber}
                             onKeyDown={blockInvalidChar}
                    />
                </div>
            </div>
            <div className="inputFieldElement">
                <label>Confirm Account Number</label>
                <div className="paddedInput">
                    <SlInput style={{width: "20ch"}}
                             name={"confirmAccountNumber"}
                             onInput={handleSlInput}
                             noSpinButtons={true}
                             type={"number"}
                             helpText={confirmAccountNumberError}
                             className={confirmAccountNumberError.length >0 ?'warn' : ''}
                             value={bankInformation.confirmAccountNumber}
                             onKeyDown={blockInvalidChar}
                    />
                </div>
            </div>
            <div>
                {isLoading  && <LoadingSpinner /> }
                <button className="button"  onClick={backButtonHandler}>Back</button>
                <button className="button"  onClick={submitButtonHandler} disabled={isLoading}>Complete</button>
            </div>
            <div>
                <span style={{color: "red"}}>{submitError}</span>
            </div>
        </div>
    </div>);
}
