import {BaseService} from "../BaseService";
import axios from "axios";
import authHeader from "../auth/auth-header";
import {Login} from "../../domain/user/Login";
import {User} from "../../domain/user/User";
import ILogin from "../../types/user/login.type";
import IUser from "../../types/user/user.type";
const userUrl = BaseService.BACKEND_URL +'/user';

 class UserService extends BaseService {

     paths = {
         getUserById: (userId:number) => userUrl  + '/' +userId,
         getPassword: (userId:number) => userUrl +'/changePassword/' + userId,
         changePassword: (login: Login) => userUrl +'/changePassword',
         getUsers: () => userUrl +'/list',
         updateUser: (user:User) => userUrl,
         addUser: (user:User) => userUrl
     }
  public async getUser(userId: number): Promise<IUser>{
      return  axios.get(this.paths.getUserById(userId) ,{ headers: authHeader() } );
  }
     public async getPassword(userId: number): Promise<ILogin>{
         const response = await axios.get(this.paths.getPassword(userId) ,{ headers: authHeader() } );
         return response.data;
     }
     public async changePassword(login: Login){
         return await axios.post(this.paths.changePassword(login) , login,{ headers: authHeader() } );
     }
     public async getUsers(): Promise<User[]>{
         const response = await axios.get(this.paths.getUsers() ,{ headers: authHeader() } );
         return response.data;
     }
     public async updateUser(user: User){
         return await axios.put(this.paths.updateUser(user) , user,{ headers: authHeader() } );
     }
     public async addUser(user: User){
         return await axios.post(this.paths.updateUser(user) , user,{ headers: authHeader() } );
     }
}
export default new UserService();
