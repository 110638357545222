import {Address} from "../core/Address";

export class Payee {
    constructor() {
        this.id=0;
        this.name='';
        this.address = new Address();
        this.emailAddress='';
        this.parentPlatformId='';
        this.distributerPlatformId='';
        this.stripeAccountToken='';
        this.status='';
        this.linkExpirationDate= '';
        this.dateOnbordingSent= '';
        this.nonElectronicPayee=false;
        this.apiUser='';
    }
id:number;
name:string;
address:Address;
emailAddress:string;
parentPlatformId:string;
distributerPlatformId:string;
stripeAccountToken:string;
status:string;
linkExpirationDate:string;
dateOnbordingSent:string;
nonElectronicPayee:boolean;
apiUser:string;
}
