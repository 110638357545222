import React from "react";

interface IProps {
    amount: number;
}

const Currency: React.FC<IProps> = ({ amount }) => {
    return (
        <>
            {Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD' // Change this
            }).format(amount)}
        </>
    );
}

export default Currency;
