import React from "react";


export default function AuthencationError() {
    return (
        <div>
    <div className="container">
        <div>
            <h1>Onboarding Failure</h1>
            <span>The onboarding link has expired or is invalid.  Please contact Buyers Edge Platform support for a new token</span>
        </div>
    </div>
    </div>
    );
}
