import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./dateRangeSelector.scss"
import React, {useState} from "react";

interface DateSelectorProps {
    startDate: Date;
    stopDate: Date;
    startDateSelected: (arg: Date) => void
    stopDateSelected: (arg: Date) => void;
}

export function DateRangeSelector ({startDate,stopDate,startDateSelected,stopDateSelected}:DateSelectorProps) {
    const setInitialToDate = () =>{
        var date = new Date();
        date.setMonth(date.getMonth()+1);
        return date;
    }
    const setInitialFromDate = () =>{
        var date = new Date();
        date.setMonth(date.getMonth()-1);
        return date;
    }

    const selectFromDate = (d: any) => {
        setFromDate(d)
        startDateSelected(d);
    }
    const selectToDate = (d: any) => {
        setToDate(d)
        stopDateSelected(d);
    }
    const [fromDate, setFromDate] = useState<Date>(startDate);
    const [toDate, setToDate] = useState<Date>(stopDate);


    return (
        <div className="dateSelect">
            <div  className="dateRangeRangeText">Date Range:</div>
            <DatePicker
                selected={fromDate}
                onChange={selectFromDate}
                maxDate={toDate}
            />
             <div   className="dateRangeToText"> To </div>
            <DatePicker
                selected={toDate}
                minDate={fromDate}
                onChange={selectToDate}
            />
        </div>
    )

}
