import React, {useEffect, useState} from "react";
import {Header} from "../../../components/header/Header";
import '../payorList/payorList.scss'
import {Payor} from "../../../domain/payor/Payor";
import PayorService from "../../../service/payor/payorService"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import SlInput from "@shoelace-style/shoelace/dist/react/input";
import type SlInputElement from "@shoelace-style/shoelace/dist/components/input/input.component";
export const PayorList = (): JSX.Element => {
    useEffect(() => {
        //getPayorData();
    }, []);
    const navigate = useNavigate();
    const [payors, setPayors] = useState<Payor[]>([]);
    const [payorSearch, setPayorSearch] = useState<string>("");
    const getPayorData = (search:string) =>{
        PayorService.payorSearch(search).then((payorData)=>{
            setPayors(payorData);
            }
        )
    }
    const handleEditPayor = (id:number) =>  (event: React.MouseEvent<SVGSVGElement>) =>{
        const payorId:number = payors[id].id;
        navigate("/payor/" + payorId);
        window.location.reload();
    }

    const handleSlInput = (event: React.FormEvent<SlInputElement>) => {
        setPayorSearch(event.currentTarget.value)
        if(event.currentTarget.value.length>=3){
            getPayorData(event.currentTarget.value);
        }
        if(event.currentTarget.value.length==0){
            setPayors([])
        }
    };

    return (
        <div>
            <Header isLoggedIn={true}></Header>
            <div className="container">
                <h1>Payors</h1>
                <div className="_payor_list_search">
                    <div>
                    <label>Name Search:</label>
                    </div>
                    <div>
                    <SlInput style={{width: "40ch"}}
                             name={"payorSearch"}
                             onInput={handleSlInput}
                             value={payorSearch}
                    />
                    </div>
                </div>

                <div className="_payor-headers _payor_header_color">
                    <div></div>
                    <div>Name</div>
                    <div>Platform Id</div>
                    <div>email</div>
                    <div>Phone</div>
                </div>
                <div>
                    <hr></hr>
                </div>
                {payors.map((payor, index) => {
                    return (
                        <div key={index} className="_payor-headers">
                            <div><FontAwesomeIcon icon="pen" onClick={handleEditPayor(index)}/></div>
                            <div>{payor.name}</div>
                            <div>{payor.platformId}</div>
                            <div>{payor.email}</div>
                            <div>{payor.phone}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}
