import {BaseService} from "../BaseService";
import {Payor} from "../../domain/payor/Payor";
import axios from "axios";
import authHeader from "../auth/auth-header";
import {PayorBankInfo} from "../../domain/payor/PayorBankInfo";
const payorUrl = BaseService.BACKEND_URL +'/paymentmethod';
class PayorPaymentMethodService extends BaseService {
    paths = {
        getBankInformation: (platformId:string) => payorUrl  + '/bankInformation/' + platformId,
    }
    public async getPayorsBankInfo(platformId:string): Promise<PayorBankInfo[]>{
        const response = await axios.get(this.paths.getBankInformation(platformId) ,{ headers: authHeader() } );
        return response.data;
    }
}
export default new PayorPaymentMethodService();