import axios from "axios";
import jwt_decode from 'jwt-decode';
import {BaseService} from "../BaseService";

import {setCookie,getCookie,deleteCookie} from "./cookie-typescript-utils";
import IUser from "../../types/user/user.type";

const API_URL = BaseService.BACKEND_URL +'/authentication/';

class AuthService extends BaseService{

  login(email: string, password: string) {
    return axios
      .post(API_URL + "login", {
        email,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          setCookie('PAYMENT_SESSION', response.data.accessToken)
        }
        var user = {
          "userName": response.data.userName,
          "id": response.data.id,
          "email": response.data.email
        }
        var isAdmin = this.isAdmin();
        localStorage.setItem("user", JSON.stringify(user));
        return response.data;
      });
  }

  logout() {
    deleteCookie('PAYMENT_SESSION')
  }

  register(username: string, email: string, password: string) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }

  getCurrentUserName() {
    const userStr: IUser = JSON.parse(<string>localStorage.getItem("user"));
    return userStr;
  }

  isUserLoggedIn(){
    try {
      var cookie = getCookie('PAYMENT_SESSION');
      const jwtToken: string = cookie !== undefined ? cookie : '';
      const decodedToke = this.getDecodedAccessToken(jwtToken)
      var date = new Date();
      var epoicTime = Math.ceil(date.getTime() / 1000);
      if (decodedToke.exp < epoicTime) {
        return false;
      } else {
        return true
      }
    }
    catch (e) {
      return false;
    }
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch(Error) {
      return null;
    }
  }

  isAdmin() {
    var cookie = getCookie('PAYMENT_SESSION');
    const jwtToken: string = cookie !== undefined ? cookie : '';
    const decodedToke = this.getDecodedAccessToken(jwtToken)
    if(decodedToke ===null){
      return false;
    }
    var roles = decodedToke.roles;
    return roles.includes('ADMIN');
  }
}


export default new AuthService();
