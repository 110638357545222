import {Address} from "../core/Address";

export class Payor {
    constructor() {
        this.id=0;
        this.name='';
        this.email='';
        this.address = new Address();
        this.phone='';
        this.platformId='';
        this.customerToken='';
        this.defaultSource='';
        this.taxId='';
        this.apiUser='';
    }
    id:number;
    name:string;
    email:string;
    address:Address
    phone:string;
    platformId:string;
    defaultSource:string;
    customerToken:string;
    taxId:string;
    apiUser:string;
}
