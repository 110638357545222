import React, {ChangeEvent, useState} from "react";
import {Payee} from "../../../domain/payee/Payee";
import PayeeService from "../../../service/payee/payeeService"
import "./payeeSearch.scss"

type PayeeSearchProps = {
    selectPayee: (arg: Payee) => void
}


export const PayeeSearch : React.FC<PayeeSearchProps> = (props) => {
    const [payeeName, setPayeeName] = useState<string>('');
    const [payees, setPayees] = useState<Payee[]>([]);
    const [displayDropdown,setDisplayDropdown] = useState<boolean>(false);
    const getPayeeData = () =>{
        PayeeService.payeeSearch(payeeName).then((payeeData)=>{
                setPayees(payeeData);
                if(payeeData.length >0){
                    setDisplayDropdown(true);
                }
            }
        )
    }

    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        setPayeeName(event.target.value,);
        if(payeeName.length>=3) {
            getPayeeData();
        }
    };

    const handleSelectPayee = (id:number) =>  (event: React.MouseEvent) =>{
        props.selectPayee(payees[id]);
    }

    return (
        <div>
            <div><input type="text"  name="name"  value={payeeName} onChange={handleInput}
                        style = {{width: `50ch`}}/></div>
            {displayDropdown &&
            <div className="_payee_search">
                    {payees.map((payee,index) => (
                        <div className="_payee_search_select" style={{width: `50ch`}} key={payee.id} onClick={handleSelectPayee(index)}>{payee.name} </div>
                    ))}
            </div>
            }
        </div>
    )
}
