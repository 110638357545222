import React from 'react';
import {useState, useEffect, ChangeEvent}  from 'react';
import Iuser from '../../../types/user/user.type';
import UserService  from '../../../service/user/userService';
import {Header} from "../../../components/header/Header";
import { useNavigate} from "react-router-dom";
import '../../../sass/_form_layout.scss'
import ILogin from "../../../types/user/login.type";
import {Login} from "../../../domain/user/Login";

interface IchangePassword {
    id: number
    currentPassword: string,
    newPassword:string
}

export const PasswordChange = (): JSX.Element => {
    useEffect(() => {
        getUserData();
    }, []);




    const navigate = useNavigate();

    const [passwordChange, setPasswordChange] = useState<IchangePassword>({
        id:0,
        currentPassword:'',
        newPassword:''
    })



    const [currentPassword,setCurrentPassword] = useState("");
    const [submitError,setSubmitError] = useState("");
    const [currentPasswordError,setCurrentPasswordError] = useState("");
    const [newPasswordError,setNewPasswordError] = useState("");




    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        const elementName = event.target.name;
        setPasswordChange({
            ...passwordChange,
            [elementName]: event.target.value,
        });
    };

    const saveButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        let login: Login = new Login();
        login.id = passwordChange.id;
        login.password = passwordChange.newPassword;
        if(!validate()) {
            UserService.changePassword(login).
            then(() => {
                navigate("/main");
                window.location.reload();
            })
                .catch((response:string) => {
                    setCurrentPasswordError(response);
                });
        }
    }

    const cancelButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) =>{
        event.preventDefault();
        navigate("/main");
        window.location.reload();
    }

    const getUserData = () => {
        const user = JSON.parse(localStorage.getItem('user') as string);

        const userData = UserService.getPassword(user.id);
        userData.then((userdata) =>{
            moveData(userdata);
        });
    }
    const moveData = (response:ILogin ) => {
        setPasswordChange(passwordChange => ({...passwordChange, id: response.id}));
        setCurrentPassword(response.password);
    }

    const validate = () =>{
        let error: boolean = false;
        setCurrentPasswordError("");
        setNewPasswordError("");
        if(!checkNewPassword(passwordChange.newPassword)) {
            error = true;
        }
        return error;
    }

    const checkNewPassword = (password: string) =>{
        if(password.length<8){
            setNewPasswordError("new password must be >=8");
            return false
        }
        return true;
    }

    return (
        <div>
            <Header isLoggedIn={true}></Header>
            <div className="container">
                <label>Current Password</label> <label></label>
                <div>
                    <input type="password"  name="currentPassword"  value={passwordChange.currentPassword} onChange={handleInput}
                           style = {{width: `50ch`}}/>
                </div>
                <div>
                    <span style={{color: "red"}}>{currentPasswordError}</span>
                </div>
                <label>New Password</label> <label></label>
                <div>
                    <input type="password"  name="newPassword"  value={passwordChange.newPassword} onChange={handleInput}
                           style = {{width: `50ch`}}/>
                </div>
                <div>
                    <span style={{color: "red"}}>{newPasswordError}</span>
                </div>
                <div>
                    <button className="okButton" onClick={saveButtonHandler}>Save</button>
                    <button className="cancelButton" onClick={cancelButtonHandler}>Cancel</button>
                </div>
                <div>
                    <span style={{color: "red"}}>{submitError}</span>
                </div>
            </div>
        </div>
    )}
