import * as React from 'react'
import {useState, useEffect}  from 'react';
import { useParams,useNavigate } from "react-router-dom";
import {SupplierOnboardingHeader} from '../../components/supplierOnboarding/SupplierOnboardingHeader';
import AuthencationError from '../../components/supplierOnboarding/SupplierOnboardingAuthenticationError';
import {submitForm} from '../../service/supplierOnboarding/OnboardingService'
export const AuthenticationPage = (): JSX.Element => {
    const { onbordingToken } = useParams() as any;
    const navigate = useNavigate();
    useEffect(() => {
        authenticate();

    }, []);
    const [authencationError,setauthencationError] = useState(false);
    const authenticate = () =>{
        const url =  'payeeOnboarding/' + onbordingToken;

        submitForm('POST', url, null, (status) => {
            console.log('statue=', status);
            if (status === 200 || status === 201) {
                navigate('/onboarding/businessProfile/' + onbordingToken);
            } else {
                console.log('ERROR?!...');
                setauthencationError(true);
            }
        }).catch(error => {
                console.log(error);
            setauthencationError(true);
            }
        );

    }

    return (
    <div>
        <SupplierOnboardingHeader payee=""></SupplierOnboardingHeader>
        {authencationError &&
        <AuthencationError></AuthencationError>
    }
    </div> )
}
