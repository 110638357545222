import {Header} from "../../../components/header/Header";
import React, {ChangeEvent, useState} from "react";
import {useNavigate} from "react-router-dom";
import '../userForm/userForm.scss'
import {User} from "../../../domain/user/User";
import {Payor} from "../../../domain/payor/Payor";
import UserService  from '../../../service/user/userService';
import SlInput from '@shoelace-style/shoelace/dist/react/input';
import type SlInputElement from '@shoelace-style/shoelace/dist/components/input/input.component.js'
export const UserForm = (): JSX.Element => {
    const navigate = useNavigate();
    const [adminCheck,setAdminCheck] = useState(false)
    const [userCheck,setUserCheck] = useState(false)
    const [supplierCheck,setSupplierCheck] = useState(false)
    const[firstNameError,setFirstNameError] = useState("");
    const[lastNameError,setLastNameError] = useState("");
    const[emailError,setEmailError] = useState("");
    const[passwordError,setPasswordError] = useState("");
    const[rolesError,setRolesError] = useState("");
    const [user, setUser] = useState<User>({
        roles:[],
        id:0,
        lastName:'',
        firstName:'',
        email:'',
        password:''
    })
    const handlecheckBox = (event: ChangeEvent<HTMLInputElement>) => {
        const elementName = event.target.name;
        if(elementName==='adminCheckBox'){
            if(event.target.checked){
                setAdminCheck(true);
            } else{
                setAdminCheck(false);
            }
        }
        if(elementName==='userCheckBox'){
            if(event.target.checked){
                setUserCheck(true);
            } else{
                setUserCheck(false);
            }
        }
        if(elementName==='supplierCheckBox'){
            if(event.target.checked){
                setSupplierCheck(true);
            } else{
                setSupplierCheck(false);
            }
        }
    }

    const handleSlInput = (event: React.FormEvent<SlInputElement>) => {
        const elementName = event.currentTarget.name;

        setUser({
            ...user,
            [elementName]: event.currentTarget.value,
        });
    };


    const saveButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if(!validateForm()) {
            setNewRoles();
            UserService.addUser(user).then(() => {
                    navigate("/userList");
                    window.location.reload();
                }
            )
        }
    }

    const cancelButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        navigate("/userList");
        window.location.reload();
    }

    const validateForm = ()  => {
        let error = false;
        setFirstNameError("");
        setLastNameError("");
        setEmailError("");
        setPasswordError("")
        setRolesError("")
        if(user.firstName?.length===0) {
            setFirstNameError("First Name cannot be blank")
            error=true;
        }
        if(user.lastName?.length===0){
            setLastNameError("Last Nme cannot be blank")
            error=true;
        }
        if(user.email?.length===0){
            setEmailError("email cannot be blank")
            error=true;
        }
        if(user.password?.length<4){
            setPasswordError("password cannot be blank.  Must be at least 4 long")
            error=true;
        }
        if(!adminCheck && !userCheck && !supplierCheck){
            setRolesError("Must select a role")
            error=true;
        }
        return error;
    }
    const setNewRoles = () =>{
        const newRoles:string[] = [];
        if(adminCheck){
            newRoles.push('ADMIN')
        }
        if(userCheck){
            newRoles.push('USER')
        }
        if(supplierCheck){
            newRoles.push('SUPPLIER')
        }
        user.roles = newRoles;
    }

    return (
        <div>
            <Header isLoggedIn={true}></Header>
            <div className="container">
                <div>
                    <div className="label">
                        First Name
                    </div>
                </div>
                <div>
                    <SlInput style={{width: "50ch"}}
                             name={"firstName"}
                             onInput={handleSlInput}
                             helpText={firstNameError}
                             className={firstNameError.length > 0 ? 'warn' : ''}
                             value={user.firstName}
                    />
                </div>
                <div>
                    <div className="label">
                        Last Name
                    </div>
                </div>
                <div>
                    <div>
                        <SlInput style={{width: "50ch"}}
                                 name={"lastName"}
                                 onInput={handleSlInput}
                                 helpText={lastNameError}
                                 className={lastNameError.length > 0 ? 'warn' : ''}
                                 value={user.lastName}
                        />
                    </div>
                </div>
                <div>
                    <div className="label">
                        Email
                    </div>
                </div>
                <div>
                    <div>
                        <SlInput style={{width: "50ch"}}
                                 name={"email"}
                                 onInput={handleSlInput}
                                 helpText={emailError}
                                 className={emailError.length > 0 ? 'warn' : ''}
                                 value={user.email}
                        />
                    </div>
                </div>
                <div>
                    <div className="label">
                        Password
                    </div>
                </div>
                <div>
                    <div>
                        <SlInput style={{width: "50ch"}}
                                 name={"password"}
                                 type={"password"}
                                 onInput={handleSlInput}
                                 helpText={passwordError}
                                 className={passwordError.length > 0 ? 'warn' : ''}
                                 value={user.password}
                        />
                    </div>
                </div>
                <div>
                    <div>Admin<input type="checkbox" name="adminCheckBox" checked={adminCheck}
                                     onChange={handlecheckBox}/></div>
                    <div>User<input type="checkbox" name="userCheckBox" checked={userCheck} onChange={handlecheckBox}/>
                    </div>
                    <div>Supplier<input type="checkbox" name="supplierCheckBox" checked={supplierCheck}
                                        onChange={handlecheckBox}/></div>
                    <span style={{color: "red"}}>{rolesError}</span>
                </div>
                <div className="_buttons">
                <div></div>
                    <button className="okButton" onClick={saveButtonHandler}>Save</button>
                    <div></div>
                    <button className="cancelButton" onClick={cancelButtonHandler}>Cancel</button>
                </div>
            </div>
        </div>
    )
}
