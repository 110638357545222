import * as React from 'react'
import {useState,useEffect, ChangeEvent}  from 'react';
import { useParams,useNavigate } from "react-router-dom";
import {SupplierOnboardingHeader} from '../../components/supplierOnboarding/SupplierOnboardingHeader';
import {submitForm,restGetData} from '../../service/supplierOnboarding/OnboardingService'
import SlInput from '@shoelace-style/shoelace/dist/react/input';
import type SlInputElement from '@shoelace-style/shoelace/dist/components/input/input.component.js'
// import NumberFormat from 'react-number-format';
import { PatternFormat } from 'react-number-format';
import './OnboardingStyle.scss';

interface IPrincipleContactPage {
    name: string;
    contactLastName: string;
    contactFirstName: string;
    contactEmail: string;
    contactMobile: string;
}


export const PrincipleContactPage = (): JSX.Element => {
    const [principleContact, setprincipleContact] = useState<IPrincipleContactPage>({
        name:'',
        contactLastName: '',
        contactFirstName: '',
        contactEmail: '',
        contactMobile: ''
    })

    const [contactLastNameError,setContactLastNameError] = useState("");
    const [contactFirstNameError,setContactFirstNameError] = useState("");
    const [contactEmailError,setContactEmailError] = useState("");
    const [contactMobileError,setContactMobileError] = useState("");
    const [submitError,setSubmitError] = useState("");

    const { onbordingToken } = useParams() as any
    const navigate = useNavigate();
    useEffect(() => {
        getData();
    }, []);
    const getData = () =>{
        const url =  'payeeOnboarding/principleContact/' + onbordingToken;
        restGetData('GET', url, (status, response) => {
            if (status === 200 || status === 201) {
                moveData(response);
            } else {
                if(response.errorCode ==='BEP0020'){
                    setSubmitError(response.stripeMessage)
                } else{
                    setSubmitError(response.errorMessage);
                }
            }
        }).catch(error => {
                console.log(error);
            setSubmitError(error);
            }
        );
    }
    const moveData = (response:IPrincipleContactPage) => {
        setprincipleContact(principleContact => ({...principleContact, name: response.name}));
        if (response.contactFirstName != null) {
            setprincipleContact(principleContact => ({
                ...principleContact,
                contactFirstName: response.contactFirstName
            }));
        }
        if(response.contactLastName !=null) {
            setprincipleContact(principleContact =>({...principleContact,contactLastName:response.contactLastName}));
        }
        if(response.contactEmail !=null){
            setprincipleContact(principleContact =>({...principleContact,contactEmail:response.contactEmail}));
        }
        if(response.contactMobile !=null){
            setprincipleContact(principleContact =>({...principleContact,contactMobile:response.contactMobile}));
        }

    }
    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        const elementName = event.target.name;
        setprincipleContact({
            ...principleContact,
            [elementName]: event.target.value,
        });
    };

    const handleSlInput = (event: React.FormEvent<SlInputElement>) => {
        const elementName = event.currentTarget.name;
        setprincipleContact({
            ...principleContact,
            [elementName]: event.currentTarget.value,
        });
    };

    const backButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        navigate('/onboarding/businessProfile/' + onbordingToken);
    }
    const submitButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if( validate()){
            return;
        }
        const url =  'payeeOnboarding/principleContact/' + onbordingToken;
        submitForm('POST', url, principleContact, (status, response) => {
            if (status === 200 || status === 201) {

            } else {
                console.log('ERROR?!...', response);

            }
        }).catch(error => {
                console.log(error);
            }
        );
        navigate('/onboarding/bankInformation/' + onbordingToken);
    };

    const validate = () => {
        let error: boolean = false;
        setContactFirstNameError('');
        setContactLastNameError('');
        setContactEmailError('');
        setContactMobileError('');
        if(principleContact.contactFirstName.length===0){
            setContactFirstNameError('Contact first name is required');
            error = true;
        }
        if(principleContact.contactLastName.length===0){
            setContactLastNameError('Contact last name is required');
            error = true;
        }
        if(principleContact.contactEmail.length===0){
            setContactEmailError('Contact email is required');
            error = true;
        } else {
            if(!isEmail(principleContact.contactEmail)){
                setContactEmailError('Contact email is not valid email');
                error = true;
            }
        }
        if(principleContact.contactMobile.length===0){
            setContactMobileError('Contact mobile number is required');
            error = true;
        }
        return error;
    }

    const isEmail = (emailAddress:string) =>{
        var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return regexp.test(emailAddress);
    }

    return (<div>
        <SupplierOnboardingHeader payee={principleContact.name}></SupplierOnboardingHeader>
        <div className="container">
            <h1>Remittance Contact Information</h1>

            <div className="inputFieldElement">
                <label>First Name</label>
                <div className="paddedInput">
                    <SlInput style={{width: "50ch"}}
                             name={"contactFirstName"}
                             onInput={handleSlInput}
                             helpText={contactFirstNameError}
                             className={contactFirstNameError.length >0 ?'warn' : ''}
                             value={principleContact.contactFirstName}
                    />
                </div>
            </div>
            <div className="inputFieldElement">
                <label>Last Name</label>
                <div className="paddedInput">
                    <SlInput style={{width: "50ch"}}
                             name={"contactLastName"}
                             onInput={handleSlInput}
                             helpText={contactLastNameError}
                             className={contactLastNameError.length >0 ?'warn' : ''}
                             value={principleContact.contactLastName}
                    />
                </div>
            </div>
            <div className="inputFieldElement">
                <label>Email</label>
                <div className="paddedInput">
                    <SlInput style={{width: "50ch"}}
                             name={"contactEmail"}
                             onInput={handleSlInput}
                             helpText={contactEmailError}
                             className={contactEmailError.length >0 ?'warn' : ''}
                             value={principleContact.contactEmail}
                    />
                </div>
            </div>
            <div className="inputFieldElement">
                <label>Phone Number</label>
                <div className="paddedInput">
                    <SlInput style={{width: "50ch"}}
                             name={"contactMobile"}
                             onInput={handleSlInput}
                             helpText={contactMobileError}
                             className={contactMobileError.length >0 ?'warn' : ''}
                             value={principleContact.contactMobile}
                    />
                </div>
            </div>
            <div>
                <button className="button" onClick={backButtonHandler}>Back</button>
                <button className="button" onClick={submitButtonHandler}>Next</button>
            </div>
            <div>
                <span style={{color: "red"}}>{submitError}</span>
            </div>
        </div>
    </div>);
}
