import React from 'react';
import SlSelect from '@shoelace-style/shoelace/dist/react/select';
import SlOption from '@shoelace-style/shoelace/dist/react/option';

type SelectProps = {
    options: {
        label: string,
        value: string,
    }[],
    header: string,
    value: string,
    name: string,
    handleSelectInput: any,
}

export const CustomSelect: React.FC<SelectProps> = (props) => {
    return(
      <div>
          <label>{props.header}</label>
          <br />
          <div className="paddedInput">
          <SlSelect value={props.value} name={props.name} onSlChange={props.handleSelectInput}>
              {
                  props.options.map((option) => {
                    return <SlOption key={option.value} value={option.value}>{option.label}</SlOption>
                  })
              };
          </SlSelect>
          </div>
      </div>
  );
}
