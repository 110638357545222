import {Header} from "../../../../components/header/Header";
import React, {useEffect, useState} from "react";
import {Payee} from "../../../../domain/payee/Payee";
import {AchControls} from "../../../../domain/ach/AchControls";
import AchService from "../../../../service/ach/AchService";
import {useNavigate, useParams} from "react-router-dom";
import SlInput from "@shoelace-style/shoelace/dist/react/input";
import type SlInputElement from "@shoelace-style/shoelace/dist/components/input/input.component";
import PayeeService from "../../../../service/payee/payeeService";
import "../achControlsForm/achControlForm.scss"

export const AchControlForm = (): JSX.Element => {
    const { id } = useParams() as any
    const navigate = useNavigate();
    useEffect(() => {
        getData();
    }, []);
    const [achControl, setAchControl] = useState<AchControls>({
        apiPassword: "",
        archivalAwsBucketName: "",
        bepAccountHoldersName: "",
        bepAccountNumber: "",
        bepRoutingNumber: "",
        clientId: "",
        clientSecret: "",
        id: 0,
        immediateDestination: 0,
        immediateDestinationName: "",
        immediateOrigin: 0,
        immediateOriginName: "",
        password: "",
        pin: "",
        type: "",
        userName: ""

    });
    const[userNameError,setUserNameError] = useState("");
    const[passwordError,setPasswordError] = useState("");
    const[immediateDestinationError,setImmediateDestination] = useState("");
    const[immediateDestinationNameError,setImmediateDestinationName] = useState("");
    const[immediateOriginError,setImmediateOrginError] = useState("");
    const[immediateorginNameError,setImmediateOrginName] = useState("");
    const[pinError,setPinError] = useState("");
    const[clientIdError,setClientIdError] = useState("");
    const[clientSecretError,setClientSecretError] = useState("");
    const[s3Error,setS3Error] = useState("");
    const[bepRoutingError,setBepRoutingError] = useState("");
    const[bepAccountError,setBepAccountError] = useState("");
    const blockInvalidChar = (e: { key: string; preventDefault: () => any; }) => ['e', 'E', '+', '-','.'].includes(e.key) && e.preventDefault();

    const getData = () => {
        AchService.getAchControlsById(id).then((achControlsData)=>{
                moveData(achControlsData)
            }
        )

    }

    const moveData = (achControls:AchControls) => {
        setAchControl(achControls)
    }

    const handleSlInput = (event: React.FormEvent<SlInputElement>) => {
        const elementName = event.currentTarget.name;
        if(elementName ==='bepRoutingNumber' && event.currentTarget.value.length > 9 ){
            setBepRoutingError('Routing number cannot be greater that 9 long');
        } else{
            setBepRoutingError('');
        }
        setAchControl({
            ...achControl,
            [elementName]: event.currentTarget.value,
        });
    };

    const saveButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if(!validateForm()) {

            AchService.updateAchControls(achControl).then(() => {
                    navigate("/achControlsList");
                    window.location.reload();
                }
            )
        }
    }

    const cancelButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        navigate("/achControlsList");
        window.location.reload();
    }

    const validateForm = ()  => {
        let error = false;
        return error;
    }

    return (
        <div>
            <Header isLoggedIn={true}></Header>
            <div className="container">
                <div className="_achcontrol-form">
                    <div>
                        <div>
                            <label>Type: </label>
                            {achControl.type}
                        </div>
                        <div className="inputFieldElement">
                            <label>User Name</label>
                            <div className="paddedInput">
                                <SlInput style={{width: "50ch"}}
                                         name={"userName"}
                                         onInput={handleSlInput}
                                         helpText={userNameError}
                                         className={userNameError.length > 0 ? 'warn' : ''}
                                         value={achControl.userName}
                                />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label>Password</label>
                            <div className="paddedInput">
                                <SlInput style={{width: "50ch"}}
                                         name={"apiPassword"}
                                         onInput={handleSlInput}
                                         helpText={passwordError}
                                         className={passwordError.length > 0 ? 'warn' : ''}
                                         value={achControl.apiPassword}
                                />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label>AWS S3 Bucket Name</label>
                            <div className="paddedInput">
                                <SlInput style={{width: "50ch"}}
                                         name={"archivalAwsBucketName"}
                                         onInput={handleSlInput}
                                         helpText={s3Error}
                                         className={s3Error.length > 0 ? 'warn' : ''}
                                         value={achControl.archivalAwsBucketName}
                                />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label>Pin</label>
                            <div className="paddedInput">
                                <SlInput style={{width: "50ch"}}
                                         name={"pin"}
                                         onInput={handleSlInput}
                                         helpText={pinError}
                                         className={pinError.length > 0 ? 'warn' : ''}
                                         value={achControl.pin}
                                />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label>Client Id</label>
                            <div className="paddedInput">
                                <SlInput style={{width: "50ch"}}
                                         name={"clientId"}
                                         onInput={handleSlInput}
                                         helpText={clientIdError}
                                         className={clientIdError.length > 0 ? 'warn' : ''}
                                         value={achControl.clientId}
                                />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label>Client Secret</label>
                            <div className="paddedInput">
                                <SlInput style={{width: "50ch"}}
                                         name={"clientSecret"}
                                         onInput={handleSlInput}
                                         helpText={clientSecretError}
                                         className={clientSecretError.length > 0 ? 'warn' : ''}
                                         value={achControl.clientSecret}
                                />
                            </div>
                        </div>

                    </div>
                    <div>
                        <div style={{marginTop: "17px"}}>

                        </div>
                        <div className="inputFieldElement">
                            <label>Immediate Destination</label>
                            <div className="paddedInput">
                                <SlInput style={{width: "50ch"}}
                                         name={"immediateDestination"}
                                         type={"number"}
                                         noSpinButtons={true}
                                         onInput={handleSlInput}
                                         helpText={immediateDestinationError}
                                         className={immediateDestinationError.length > 0 ? 'warn' : ''}
                                         value={achControl.immediateDestination.toString()}
                                         onKeyDown={blockInvalidChar}
                                />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label>Immediate Destination Name</label>
                            <div className="paddedInput">
                                <SlInput style={{width: "50ch"}}
                                         name={"immediateDestinationName"}
                                         onInput={handleSlInput}
                                         helpText={immediateDestinationNameError}
                                         className={immediateDestinationNameError.length > 0 ? 'warn' : ''}
                                         value={achControl.immediateDestinationName}
                                />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label>Immediate Origin</label>
                            <div className="paddedInput">
                                <SlInput style={{width: "50ch"}}
                                         name={"immediateOrigin"}
                                         type={"number"}
                                         noSpinButtons={true}
                                         onInput={handleSlInput}
                                         helpText={immediateOriginError}
                                         className={immediateOriginError.length > 0 ? 'warn' : ''}
                                         value={achControl.immediateOrigin.toString()}
                                         onKeyDown={blockInvalidChar}
                                />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label>Immediate Origin Name</label>
                            <div className="paddedInput">
                                <SlInput style={{width: "50ch"}}
                                         name={"immediateOriginName"}
                                         onInput={handleSlInput}
                                         helpText={immediateorginNameError}
                                         className={immediateorginNameError.length > 0 ? 'warn' : ''}
                                         value={achControl.immediateOriginName}
                                />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label>BEP Account Holder Name</label>
                            <div className="paddedInput">
                                <SlInput style={{width: "50ch"}}
                                         name={"bepAccountHoldersName"}
                                         onInput={handleSlInput}
                                         helpText={immediateorginNameError}
                                         className={immediateorginNameError.length > 0 ? 'warn' : ''}
                                         value={achControl.bepAccountHoldersName}
                                />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label>Bep Routing</label>
                            <div className="paddedInput">
                                <SlInput style={{width: "20ch"}}
                                         name={"bepRoutingNumber"}
                                         onInput={handleSlInput}
                                         noSpinButtons={true}
                                         type={"number"}
                                         helpText={bepRoutingError}
                                         className={bepRoutingError.length > 0 ? 'warn' : ''}
                                         value={achControl.bepRoutingNumber}
                                         onKeyDown={blockInvalidChar}
                                />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label>Bep Account Number</label>
                            <div className="paddedInput">
                                <SlInput style={{width: "20ch"}}
                                         name={"bepAccountNumber"}
                                         onInput={handleSlInput}
                                         noSpinButtons={true}
                                         type={"number"}
                                         helpText={bepAccountError}
                                         className={bepAccountError.length > 0 ? 'warn' : ''}
                                         value={achControl.bepAccountNumber}
                                         onKeyDown={blockInvalidChar}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="_buttons">
                    <div></div>
                    <button className="okButton" onClick={saveButtonHandler}>Save</button>
                    <div></div>
                    <button className="cancelButton" onClick={cancelButtonHandler}>Cancel</button>
                </div>
            </div>
        </div>
    )

}