import {BaseService} from "../BaseService";
import axios from "axios";
import authHeader from "../auth/auth-header";
import {Payor} from "../../domain/payor/Payor";
import authService from "../auth/auth.service";
const payorUrl = BaseService.BACKEND_URL +'/payor';
class PayorService extends BaseService {

    paths = {
        getPayors: () => payorUrl  + '/list',
        getPayorById: (id:number) => payorUrl  + '/byId/' + id,
        updatePayor: (payor:Payor) => payorUrl ,
        searchPayors: (searchParm:string) => payorUrl  + '/search' + '/' + searchParm,
    }
    public async getPayors(): Promise<Payor[]>{
        const response = await axios.get(this.paths.getPayors() ,{ headers: authHeader() } );
        return response.data;
    }
    public async getPayorById(id:number): Promise<Payor>{
        const response = await axios.get(this.paths.getPayorById(id) ,{ headers: authHeader() } );
        return response.data;
    }
    public async updatePayor(payor:Payor): Promise<Payor>{
        payor.apiUser = authService.getCurrentUserName().email;
        const response =await axios.put(this.paths.updatePayor(payor), payor,{ headers: authHeader() } );
        return response.data;
    }
    public async payorSearch(searchString:string): Promise<Payor[]>{
        const response = await axios.get(this.paths.searchPayors(searchString) ,{ headers: authHeader() } );
        return response.data;
    }
}
export default new PayorService();
