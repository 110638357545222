import {AchPayment} from "../../../domain/ach/payments/AchPayment";
import React from "react";
import "./achFilesList.scss"
import Currency from "../../../domain/core/Currency";


interface AchFilesPaymentsProps {
    achPayments:AchPayment[];
}

export function AchFilesPayments (achPayments:AchFilesPaymentsProps){

    return (
        <div style = {{marginBottom:10}}>
            <div className="_achFilesPayments_list_header _achFilesPayments_list_header_color">
                <div></div>
                <div>Id</div>
                <div>Payor</div>
                <div>Payee</div>
                <div>Date of Charge</div>
                <div style = {{textAlign:"right"}}>Charge Amount</div>
                <div style = {{textAlign:"right"}}>Payment Status</div>
            </div>
            {achPayments.achPayments.sort((a,b) => (a.dateOfCharge > b.dateOfCharge) ?1: -1).map((achPayment, index) => {
                return (
                    <div key={index} className="_achFilesPayments_list_header">
                        <div></div>
                        <div>{achPayment.id}</div>
                        <div>{achPayment.payorName}</div>
                        <div>{achPayment.payeeName}</div>
                        <div>{achPayment.dateOfCharge}</div>
                        <div style = {{textAlign:"right"}}><Currency amount={achPayment.chargeAmount}/></div>
                        <div  style = {{textAlign:"right"}}>{achPayment.paymentStatus}</div>
                    </div>
                );
            })}
        </div>
    )
}
